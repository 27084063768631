@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-RegularItalic.woff2") format("woff2"),
    url("../fonts/BrandonText-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Thin.woff2") format("woff2"),
    url("../fonts/BrandonText-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Regular.woff2") format("woff2"),
    url("../fonts/BrandonText-Regular.woff") format("woff");
  font-weight: 390;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Light.woff2") format("woff2"),
    url("../fonts/BrandonText-Light.woff") format("woff");
  font-weight: 390;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-MediumItalic.woff2") format("woff2"),
    url("../fonts/BrandonText-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Medium.woff2") format("woff2"),
    url("../fonts/BrandonText-Medium.woff") format("woff");
  font-weight: 420;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Bold.woff2") format("woff2"),
    url("../fonts/BrandonText-Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Black.woff2") format("woff2"),
    url("../fonts/BrandonText-Black.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
