.ant-checkbox-wrapper {

  .ant-checkbox {
    &.ant-checkbox-checked {
      &::after {
        border: 2px solid #8137FA;
        border-radius: 4px;
      }

      .ant-checkbox-inner {
        background-color: #8137FA;
        border-color: #8137FA;
      }
    }
  }
}