/*
@File: Mojosa Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.



*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - About CSS
** - Features CSS
** - Product New Features CSS
** - Services CSS
** - Book Discover CSS
** - Overview CSS{
	"arrowParens": "always",
	"bracketSameLine": false,
	"bracketSpacing": true,
	"embeddedLanguageFormatting": "auto",
	"htmlWhitespaceSensitivity": "css",
	"insertPragma": false,
	"jsxSingleQuote": false,
	"printWidth": 80,
	"proseWrap": "preserve",
	"quoteProps": "as-needed",
	"requirePragma": false,
	"semi": true,
	"singleQuote": false,
	"tabWidth": 2,
	"trailingComma": "es5",
	"useTabs": false,
	"vueIndentScriptAndStyle": false
}

** - CTA CSS
** - SAAS Tools CSS
** - Fun Facts CSS
** - Work Process CSS
** - Pricing CSS
** - Project CSS
** - Project Details CSS
** - Gallery CSS
** - Get Started CSS
** - How App Works CSS
** - Screenshot CSS
** - Team CSS
** - Next Generation CSS
** - Feedback CSS
** - App Download CSS
** - Platform Connect CSS
** - Blog CSS
** - Blog Details CSS
** - Boxes CSS
** - Join CSS
** - Analysis CSS
** - Partner CSS
** - Subscribe CSS
** - Product CSS
** - Product Details CSS
** - Page Title CSS
** - Pagination CSS
** - Sidebar CSS
** - Login CSS
** - Signup CSS
** - FAQ CSS
** - Error 404 CSS
** - Coming Soon CSS
** - Cart CSS
** - Checkout CSS
** - Contact Us CSS
** - Footer CSS
** - Back To Top CSS
*/

/*================================================
Default CSS
=================================================*/
@import "variables";
@import "font";
@import "spacing-helpers";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&family=Nunito:wght@500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  padding: 0;
  margin: 0;
  background-color: #f7f7f7;
  font-family: var(--fontFamily) !important;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(#001529, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: #a2afb6;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
  }

  @supports (scrollbar-width: thin) {
    scrollbar-width: thin;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  color: var(--blackColor);
  text-decoration: none;
  transition: 0.4s;
  display: inline-block;
  outline: 0;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--templateColor2);
  }
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }

  .d-title {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    span {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 60px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: -2px 1.7px 0px #ea5023, -3px 3px 5px #cf3115,
        -2px 2px 2px rgba(185, 60, 6, 0.76),
        -4.59183px 4.59183px 9.18366px #c84d19;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 54px 0px;
    }
  }
}

.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}

.ptb-50 {
  padding: {
    top: 50px;
    bottom: 50px;
  }
}

.bg-gray {
  background-color: #edf5ff;
}

.owl-carousel {
  .owl-stage {
    display: flex;
  }
}

p {
  font-size: 16px;
  color: var(--paragraphColor);
  line-height: 1.6;

  &:last-child {
    margin-bottom: 0;
  }
}

button {
  outline: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

/* btn btn-primary */
.btn-primary {
  color: var(--whiteColor);
  background-color: var(--templateColor);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--templateColor2);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--templateColor);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--templateColor2);
    border-color: var(--templateColor2);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--templateColor);
    }
  }
}

/* btn btn-blue */
.btn-blue {
  color: var(--whiteColor);
  background-color: var(--blue);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--blueActive);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--blueActive);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--blueActive);
    border-color: var(--blueActive);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--blueActive);
    }
  }
}

/* btn btn-pink */
.btn-pink {
  color: var(--whiteColor);
  background-color: var(--pink);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--pinkHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--pinkHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--pinkHover);
    border-color: var(--pinkHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--pinkHover);
    }
  }
}

/* btn btn-light-blue */
.btn-light-blue {
  color: var(--whiteColor);
  background-color: var(--lightBlue);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--lightBlueHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--lightBlueHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--lightBlueHover);
    border-color: var(--lightBlueHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--lightBlueHover);
    }
  }
}

/* btn btn-mint */
.btn-mint {
  color: var(--whiteColor);
  background-color: var(--mint);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--mintHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--mintHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--mintHover);
    border-color: var(--mintHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--mintHover);
    }
  }
}

/* btn btn-green */
.btn-green {
  color: var(--whiteColor);
  background-color: var(--green);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--greenHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--greenHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--greenHover);
    border-color: var(--greenHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--greenHover);
    }
  }
}

/* btn btn-purple */
.btn-purple {
  color: var(--whiteColor);
  background-color: var(--purpule);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--purpleHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--purpleHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--purpleHover);
    border-color: var(--purpleHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--purpleHover);
    }
  }
}

/* btn btn-yellow */
.btn-yellow {
  color: var(--whiteColor);
  background-color: var(--yellow);

  &:hover,
  &:focus,
  &:active {
    color: var(--whiteColor);
    background: var(--yellowHover);
    transform: translateY(-5px);
  }

  &.focus,
  &:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-blue.dropdown-toggle {
    color: var(--whiteColor);
    background: var(--yellowHover);
    transform: translateY(-5px);
  }

  &.disabled,
  &:disabled {
    color: var(--whiteColor);
    background-color: var(--yellowHover);
    border-color: var(--yellowHover);
    opacity: 1;

    &:hover,
    &:focus {
      background: var(--yellowHover);
    }
  }
}

.btn-mindmap-primary {
  padding: 8px 0px !important;
  text-align: center !important;
  cursor: pointer !important;
  background: linear-gradient(
    91.8deg,
    #49a568 -2.53%,
    rgba(51, 184, 48, 0.991194) 27.31%,
    rgba(30, 187, 93, 0.979067) 68.42%,
    rgba(18, 208, 71, 0.97) 99.16%
  ) !important;
  box-shadow: 0px 2px 0px 1px #03980a !important;
  border: none !important;
  border-radius: 24px !important;
  max-width: 200px;
  display: flex;
  align-items: center;
  gap: 12;

  &:hover {
    background-color: #4eb035 !important;
  }

  &:active {
    background-color: #3e8e41 !important;
    box-shadow: 0px 2px 0px 1px #0f7c14 !important;
    transform: translateY(4px) !important;
  }
}

.btn {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--whiteColor);
  background: var(--primaryGardient);
  box-shadow: 0px 8px 10px -5px rgba(39, 135, 176, 0.5);
  padding: 20.5px 25px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    padding: 8px 20px;
    height: 100%;
  }

  .btn-primary-text {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 54px;
      text-align: center;
      color: #ffffff;
      margin-left: 10px;
    }
  }

  &.block {
    width: 100%;
  }

  &:hover,
  &:focus,
  &.active {
    color: var(--whiteColor);
  }
}

//new btn
.btn-main {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 17px 32px;
  gap: 12px;
  background: #8137fa;
  border-radius: 38px;
  font-style: normal;
  font-weight: 420;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #f7f2ff;
  cursor: pointer;
  border: 1px solid transparent;

  span {
    width: 100%;
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &.create-mindmap {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #83ca00;
    border: 1px solid #83ca00;
    border-radius: 45px;
    color: #83ca00;

    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 10px;
    letter-spacing: 0.005em;
  }

  &.medium-fontsize {
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      linear-gradient(0deg, rgba(63, 162, 247, 0.1), rgba(63, 162, 247, 0.1)),
      #8137fa;
  }

  &:active,
  &.active,
  &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      #8137fa;
  }

  &.disabled,
  &:disabled {
    background: #e5e5e5;
    font-style: normal;
    font-weight: 420;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #999999;
    cursor: not-allowed;
  }
}

.btn-create-outline {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 12px;
  font-style: normal;
  font-weight: 420;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #83ca00;
  border: 1px solid #83ca00;
  border-radius: 45px;
  color: #83ca00;

  font-style: normal;
  font-weight: 420;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  letter-spacing: 0.005em;

  span {
    width: 100%;
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &.medium-fontsize {
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      #83ca00;
  }

  &:active,
  &.active,
  &:focus {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      #83ca00;
    border: 1px solid #83ca00;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.disabled,
  &:disabled {
    background: #e5e5e5;
    font-style: normal;
    font-weight: 420;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #999999;
    cursor: not-allowed;
  }
}

.btn-main-outline {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  margin: 0px 8px;
  cursor: pointer;
  margin-top: 8px;
  border: 1px solid #8137fa;
  border-radius: 70px;
  font-style: normal;
  font-weight: 420;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #8137fa;

  &.medium-fontsize {
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    border: 1px solid #6617e8;
    color: #6617e8;
  }

  &:active,
  &.active,
  &:focus {
    border: 1px solid #8137fa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.disabled,
  &:disabled {
    background: #e5e5e5;
    font-style: normal;
    font-weight: 420;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #999999;
    cursor: not-allowed;
  }
}

.btn-create-square {
  width: 132px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 17px 10px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 420;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.0125em;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45)
    ),
    #f7f2ff;
  border: 1px solid #ebe3fe;
  border-radius: 8px;

  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    background: #ebe3fe;
    border: 1px solid #ebe3fe;
    border-radius: 8px;

    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.0125em;
    color: #6e2fd5;
  }

  // &.color-f2{
  //   background: #F2F2F2;
  // }
}

/* section title */
.section-title {
  text-align: center;
  max-width: 720px;

  margin: {
    bottom: 50px;
    top: -6px;
    left: auto;
    right: auto;
  }

  h2 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin-bottom: 0;
  }

  p {
    max-width: 650px;
    margin: 0 auto;
  }

  .bar {
    height: 4px;
    width: 85px;
    background: var(--templateColor);
    margin: 20px auto 25px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 5px;
      background: var(--whiteColor);
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: MOVE-BG;
    }
  }
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(85px);
  }
}

@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(85px);
  }
}

/* form-control */
.form-group {
  margin-bottom: 20px;
}

.form-control {
  height: 50px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  transition: 0.4s;
  color: var(--paragraphColor);
  background: var(--whiteColor);

  &::placeholder {
    color: var(--paragraphColor);
  }

  &:focus {
    color: var(--paragraphColor);
    background-color: var(--whiteColor);
    border-color: var(--templateColor);
    outline: 0;
    box-shadow: unset;
  }
}

/*================================================
Preloader CSS
=================================================*/
.preloader-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background: var(--whiteColor);

  .lds-hourglass {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    margin-top: -95px;

    &::after {
      content: " ";
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 6px;
      box-sizing: border-box;
      border: 36px solid var(--templateColor);
      border-color: var(--templateColor) transparent var(--templateColor2)
        transparent;
      animation: lds-hourglass 2.9s infinite;
    }
  }
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

/*================================================
Navbar CSS
=================================================*/
.crake-nav {
  width: 100%;
  z-index: 999;
  height: auto;

  &.light {
    background: var(--whiteColor);
  }

  .navbar-toggler {
    box-shadow: none;
  }

  nav {
    padding-top: 6px;
    padding-bottom: 0;

    .navbar-brand {
      img {
        max-width: 150px;
        height: 150px;
      }

      padding: 0px;
    }

    ul {
      list-style-type: none;
    }

    .navbar-nav {
      text-transform: uppercase;
      z-index: 999;

      li {
        position: relative;
        padding: 8px 0;

        a {
          font: {
            weight: 600;
            size: 14px;
          }

          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          padding: 0 0;
          margin: 0 17px;
          position: relative;
          transition: var(--transition);

          &.nav-header-link {
            color: #1e3d74 !important;
          }

          &:hover,
          &.active {
            &::before {
              opacity: 1;
              visibility: visible;
              left: -12px;
            }
          }
        }

        &:hover {
          .nav-header-link {
            color: var(--primaryColor2) !important;

            &:hover,
            &:focus {
              color: var(--primaryColor2) !important;
            }

            &::before {
              opacity: 1;
              visibility: visible;
              left: -12px;
            }
          }
        }

        &.active {
          border-bottom: 9px solid var(--primaryColor4);

          .nav-header-link {
            color: var(--primaryColor) !important;

            &::before {
              opacity: 1;
              visibility: visible;
              left: -12px;
            }
          }
        }

        .dropdown_menu {
          background: linear-gradient(180deg, #ea5023 -36.27%, #f68317 74.61%);
          border-radius: 0px;
          position: absolute;
          top: 90px;
          left: 0;
          width: 260px;
          z-index: 99;
          padding: 20px 0;
          opacity: 0;
          border-radius: 5px;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          li {
            position: relative;
            padding: 20px !important;
            border-bottom: 2px solid rgba(255, 198, 26, 0.2);

            &:last-child {
              border: none;
            }

            a {
              font: {
                size: 15px;
              }

              padding: 5px 15px !important;
              margin: 0;
              display: block;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff !important;

              &::before {
                left: 0;
                background: var(--templateColor);
                opacity: 0;
                visibility: hidden;
                top: 12px;
              }

              &:hover {
                &::before {
                  opacity: 1;
                  visibility: visible;
                }

                color: var(--templateColor) !important;
              }

              &.active {
                color: var(--templateColor) !important;

                &::before {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }

            .dropdown_menu {
              position: absolute;
              left: 95%;
              top: 0 !important;
              opacity: 0 !important;
              visibility: hidden !important;

              li {
                a {
                  color: #716c80 !important;

                  &::before {
                    opacity: 0;
                    left: 0;
                    visibility: hidden;
                  }

                  &:hover {
                    color: var(--templateColor) !important;

                    &::before {
                      left: 13px;
                      opacity: 1;
                      visibility: visible;
                    }
                  }

                  &.active {
                    color: var(--templateColor) !important;
                    padding-left: 25px !important;

                    &::before {
                      left: 13px;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }

            &:hover,
            &:focus {
              background: var(--whiteColor);

              .dropdown_menu {
                opacity: 1 !important;
                visibility: visible !important;
                top: -20px !important;
              }

              a {
                color: var(--templateColor) !important;

                &::before {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }

        &:hover {
          ul {
            opacity: 1 !important;
            visibility: visible !important;
            top: 100%;
          }
        }
      }
    }

    .others-option {
      .navbar-nav {
        align-items: center;

        .cart-wrapper {
          position: relative;
          padding: 0 !important;

          a {
            margin: 0 0 0 10px !important;

            .cart-icon {
              line-height: 52px;
              font-size: 25px;
            }

            span {
              position: absolute;
              width: 16px;
              height: 16px;
              font-size: 10px;
              background: var(--blackColor);
              display: inline-block;
              text-align: center;
              line-height: 16px;
              border-radius: 50%;
              top: 8px;
              color: var(--whiteColor);
              right: -8px;
            }
          }
        }

        li {
          padding: 0 !important;

          a {
            margin: 0 22px !important;

            &::before {
              display: none;
            }
          }

          .side-menu {
            cursor: pointer;
            width: 32px;

            .bar-1 {
              position: relative;
              width: 8px;
              height: 8px;
              background: var(--whiteColor);
              display: block;
              border-radius: 50%;
              margin-bottom: 2px;
              transition: all 0.4s ease-in-out;

              &::before,
              &::after {
                content: "";
                position: absolute;
                right: -12px;
                top: 0;
                width: 8px;
                height: 8px;
                background: var(--whiteColor);
                border-radius: 50%;
                transition: all 0.4s ease-in-out;
              }

              &::after {
                right: -24px;
              }
            }

            .bar-2 {
              position: relative;
              width: 8px;
              height: 8px;
              margin-bottom: 2px;
              background: var(--whiteColor);
              display: block;
              border-radius: 50%;
              transition: all 0.4s ease-in-out;

              &::before,
              &::after {
                content: "";
                position: absolute;
                right: -12px;
                top: 0;
                width: 8px;
                height: 8px;
                background: var(--whiteColor);
                border-radius: 50%;
                transition: all 0.4s ease-in-out;
              }

              &::after {
                right: -24px;
              }
            }

            .bar-3 {
              position: relative;
              width: 8px;
              height: 8px;
              background: var(--whiteColor);
              margin-bottom: 2px;
              display: block;
              border-radius: 50%;
              transition: all 0.4s ease-in-out;

              &::before,
              &::after {
                content: "";
                position: absolute;
                right: -12px;
                top: 0;
                width: 8px;
                height: 8px;
                background: var(--whiteColor);
                border-radius: 50%;
                transition: all 0.6s ease-in-out;
              }

              &::after {
                right: -24px;
                transition: all 0.8s ease-in-out;
              }
            }

            &:hover,
            &:focus {
              .bar-2 {
                &::after {
                  opacity: 0;
                  visibility: hidden;
                  transform: translateX(-100%);
                }
              }

              .bar-3 {
                &::after,
                &::before {
                  opacity: 0;
                  visibility: hidden;
                  transform: translateX(-100%);
                }
              }
            }
          }
        }
      }
    }
  }

  &.marketing-navbar {
    nav {
      .navbar-nav {
        li {
          a {
            color: #716c80 !important;

            &::before {
              background: var(--templateColor);
            }

            &:hover,
            &:focus,
            &.active {
              color: var(--templateColor) !important;
            }
          }

          .side-menu {
            .bar-1 {
              background: #716c80;

              &::before,
              &::after {
                background: #716c80;
              }
            }

            .bar-2 {
              background: #716c80;

              &::before,
              &::after {
                background: #716c80;
              }
            }

            .bar-3 {
              background: #716c80;

              &::before,
              &::after {
                background: #716c80;
              }
            }
          }
        }
      }
    }

    &.is-sticky {
      background: var(--whiteColor) !important;
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 999;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--whiteColor) !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}

.header-search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 9999;

  .header-search-form {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    max-width: 950px;
    margin: 0 auto;

    input {
      &[type="search"] {
        width: 100%;
        color: #222d39;
        font-size: 20px;
        text-align: left;
        border: 1px solid var(--templateColor);
        margin: 0px auto;
        padding: 25px 10px 25px 30px;
        outline: none;
        background: transparent;
        transition: 0.4s;

        &:focus {
          border-color: var(--templateColor2);
        }
      }
    }

    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      padding: 0 50px;
      border-radius: 0;

      &:hover {
        transform: translateY(0);
      }
    }
  }

  .close {
    position: absolute;
    color: var(--templateColor2);
    background-color: transparent;
    border: none;
    opacity: 1 !important;
    visibility: visible;
    padding: 0;

    font: {
      size: 25px;
      weight: 300;
    }

    border-radius: 50%;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 1px solid var(--templateColor2);
    transition: 0.4s;
    right: 24%;
    margin: -100px auto 0;
    top: 50%;
    line-height: 25px;

    &:hover,
    &:focus {
      background-color: var(--templateColor) !important;
      color: var(--whiteColor) !important;
      border-color: var(--templateColor) !important;
      opacity: 1 !important;
    }
  }

  &.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    visibility: visible;
  }
}

.sidebar-modal {
  position: relative;

  .navbar-nav {
    li {
      a {
        padding: 10px 0px 10px 15px;
      }
    }
  }

  .modal {
    &.right {
      .modal-dialog {
        position: fixed;
        margin: auto;
        width: 350px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
      }

      &.fade.show {
        .modal-dialog {
          right: 0;
          transition: all 0.3s ease-in-out;
        }
      }

      &.fade {
        .modal-dialog {
          right: -320px;
          transition: all 0.3s ease-in-out;
        }
      }

      .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
      }

      .modal-body {
        padding: 30px;
        border-left: 1px solid #eee;

        .sidebar-modal-widget {
          margin-bottom: 35px;

          .title {
            font: {
              size: 20px;
              weight: 700;
            }

            position: relative;
            z-index: 1;
            margin-bottom: 30px;

            &::before {
              position: absolute;
              left: 0;
              bottom: -7px;
              width: 70px;
              height: 2px;
              border-radius: 5px;
              background: var(--templateColor);
              content: "";
            }

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -7px;
              height: 2px;
              width: 5px;
              background: var(--whiteColor);
              animation-duration: 2s;
              animation-timing-function: linear;
              animation-iteration-count: infinite;
              animation-name: MOVE-BG;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            &.contact-info {
              li {
                position: relative;
                font-weight: 700;
                padding-left: 65px;
                text-transform: uppercase;
                margin-bottom: 17px;

                i {
                  position: absolute;
                  left: 0;
                  top: 0;
                  background: #f4f4f4 none repeat scroll 0 0;
                  border: 1px solid #e7e7e7;
                  border-radius: 5px;
                  color: var(--templateColor);
                  height: 50px;
                  text-align: center;
                  width: 50px;
                  line-height: 50px;
                  font-size: 25px;
                  transition: 0.4s;
                }

                span {
                  display: block;
                  font-weight: 420;
                  color: var(--paragraphColor);
                  margin-top: 5px;
                  text-transform: initial;
                }

                &:hover {
                  i {
                    color: var(--whiteColor);
                    border-color: var(--whiteColor);
                    background: var(--templateColor);
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            &.social-list {
              li {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 10px;

                a {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  font-size: 15px;
                  text-align: center;
                  border-radius: 50%;
                  color: var(--paragraphColor);
                  border: 1px solid var(--paragraphColor);

                  &:hover,
                  &:focus {
                    color: var(--whiteColor);
                    background: var(--templateColor);
                    border-color: var(--templateColor);
                  }
                }
              }
            }
          }
        }
      }
    }

    &.left {
      .modal-dialog {
        position: fixed;
        margin: auto;
        width: 350px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
      }

      &.fade.show {
        .modal-dialog {
          left: 0;
          transition: all 0.3s ease-in-out;
        }
      }

      &.fade {
        .modal-dialog {
          left: -320px;
          transition: all 0.3s ease-in-out;
        }
      }

      .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
      }

      .modal-body {
        padding: 30px;
        border-left: 1px solid #eee;

        .sidebar-modal-widget {
          margin-bottom: 35px;

          .title {
            font: {
              size: 20px;
              weight: 700;
            }

            position: relative;
            z-index: 1;
            margin-bottom: 30px;

            &::before {
              position: absolute;
              left: 0;
              bottom: -7px;
              width: 70px;
              height: 2px;
              border-radius: 5px;
              background: var(--templateColor);
              content: "";
            }

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -7px;
              height: 2px;
              width: 5px;
              background: var(--whiteColor);
              animation-duration: 2s;
              animation-timing-function: linear;
              animation-iteration-count: infinite;
              animation-name: MOVE-BG;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            &.contact-info {
              li {
                position: relative;
                font-weight: 700;
                padding-left: 65px;
                text-transform: uppercase;
                margin-bottom: 17px;

                i {
                  position: absolute;
                  left: 0;
                  top: 0;
                  background: #f4f4f4 none repeat scroll 0 0;
                  border: 1px solid #e7e7e7;
                  border-radius: 5px;
                  color: var(--templateColor);
                  height: 50px;
                  text-align: center;
                  width: 50px;
                  line-height: 50px;
                  font-size: 25px;
                  transition: 0.4s;
                }

                span {
                  display: block;
                  font-weight: 420;
                  color: var(--paragraphColor);
                  margin-top: 5px;
                  text-transform: initial;
                }

                &:hover {
                  i {
                    color: var(--whiteColor);
                    border-color: var(--whiteColor);
                    background: var(--templateColor);
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            &.social-list {
              li {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 10px;

                a {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  font-size: 15px;
                  text-align: center;
                  border-radius: 50%;
                  color: var(--paragraphColor);
                  border: 1px solid var(--paragraphColor);

                  &:hover,
                  &:focus {
                    color: var(--whiteColor);
                    background: var(--templateColor);
                    border-color: var(--templateColor);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-header {
    display: inline;
    padding: 0;
    border: none;
    position: relative;
    z-index: 999;

    .close {
      height: 40px;
      width: 40px;
      background: var(--templateColor);
      font-size: 20px;
      color: var(--whiteColor);
      margin: 0px;
      padding: 0px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border: none;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background: var(--templateColor2);
        color: var(--whiteColor);
        transition: all 0.3s ease-in-out;
        opacity: 1;
      }
    }

    h2 {
      background: linear-gradient(142deg, #3ec9cf 0%, #2d136a 100%);
      padding: 30px 25px;

      img {
        max-width: 100px;
      }
    }
  }

  button {
    &:focus {
      outline: 0px;
    }
  }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
  height: auto;
  background: var(--bannerColor);
  position: relative;
  z-index: 1;

  .hero-content {
    h1 {
      color: var(--whiteColor);

      font: {
        size: 60px;
        weight: 700;
      }

      margin: 0;
    }

    p {
      color: #f2f2f2;
      font-size: 18px;

      margin: {
        top: 30px;
        bottom: 35px;
      }
    }

    .btn {
      &:hover {
        &::after,
        &::before {
          background: var(--whiteColor);
        }

        transform: translateY(0);
      }
    }

    .btn-primary {
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > .btn-primary.dropdown-toggle {
        color: var(--templateColor2);
        background: var(--whiteColor);
      }

      &:hover,
      &:focus {
        background: var(--whiteColor);
        color: var(--templateColor2);
      }
    }

    .video-btn {
      margin-left: 15px;
      color: var(--whiteColor);
      text-transform: uppercase;

      font: {
        size: 15px;
        weight: 700;
      }

      i {
        font-size: 30px;
        position: relative;
        margin-left: 10px;
        top: 4px;
        transition: 0.4s;
      }

      &:hover {
        i {
          color: var(--templateColor2);
        }
      }
    }

    form {
      max-width: 540px;
      position: relative;

      .form-control {
        height: 70px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 3px;
        color: var(--whiteColor);
        padding-left: 20px;
        background: var(--templateColor);

        &::placeholder {
          color: var(--whiteColor);
        }
      }

      .btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }
  }

  .signup-download {
    background: var(--whiteColor);
    padding: 30px 40px;
    border-radius: 3px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      background: var(--whiteColor);
      width: 96%;
      opacity: 0.62;
      height: 50%;
      bottom: -6px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 3px;
      transition: 0.4s;
    }

    form {
      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 8px;

          font: {
            weight: 700;
            size: 17px;
          }
        }
      }

      .btn {
        display: block;
        width: 100%;
      }
    }
  }

  .app-image {
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: -40px;
      animation: movebounce 5s linear infinite;
      width: 400px;

      &:last-child {
        animation: movebounce 6s linear infinite;
        left: 270px;
        top: -20px;
      }
    }
  }

  .product-image {
    position: relative;

    img {
      animation: movebounce 6s linear infinite;
      width: 300px;

      &:nth-child(2) {
        position: absolute;
        right: -45px;
        bottom: -60px;
        animation: movebounce 5s linear infinite;
      }
    }

    .offer-badge {
      position: absolute;
      right: 90px;
      top: 30px;
      width: 100px;
      height: 100px;
      background: var(--templateColor2);
      color: var(--whiteColor);
      border-radius: 50%;
      text-align: center;
      animation: ripple-white 1s linear infinite;

      font: {
        size: 18px;
        weight: 700;
      }

      padding-top: 25px;

      span {
        display: block;
      }
    }
  }

  .saas-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        top: -200px;
        left: 0;
      }

      &:nth-child(2) {
        left: 55px;
        top: -100px;
      }

      &:nth-child(3) {
        left: 36%;
        z-index: 1;
        top: -170px;
      }

      &:nth-child(4) {
        right: 27%;
        top: 110px;
        z-index: 2;
      }

      &:nth-child(5) {
        right: 15%;
        top: 80px;
        z-index: 2;
      }

      &:nth-child(6) {
        right: 25%;
        top: -170px;
      }

      &:nth-child(7) {
        right: 47%;
        top: 96px;
        z-index: 2;
      }

      &:nth-child(8) {
        right: 22%;
        top: 45px;
        z-index: 1;
      }

      &:nth-child(9) {
        right: 75px;
        top: 20px;
      }

      &:nth-child(10) {
        left: 32%;
        top: -76px;
        z-index: 1;
      }

      &:nth-child(11) {
        right: 40%;
        top: -20px;
      }

      &:nth-child(12) {
        top: -292px;
        left: 45px;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .startup-image {
    position: relative;

    img {
      position: absolute;
      width: unset !important;

      &:nth-child(1) {
        top: 45px;
        right: 23%;
      }

      &:nth-child(2) {
        left: 20px;
        top: -35px;
        right: 0;
        margin: 0 auto;
      }

      &:nth-child(3) {
        right: 0;
        top: 65px;
      }

      &:nth-child(4) {
        left: 85px;
        top: 0;
      }

      &:nth-child(5) {
        left: 110px;
        top: 132px;
      }

      &:nth-child(6) {
        right: 8%;
        top: 131px;
      }

      &:nth-child(7) {
        right: 20%;
        top: -30px;
      }

      &:nth-child(8) {
        right: 38%;
        top: 64px;
      }

      &:nth-child(9) {
        right: 0;
        top: -15px;
      }

      &:nth-child(10) {
        left: 0;
        top: 245px;
        right: 0;
        margin: 0 auto;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .paymant-processing-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        top: 135px;
        right: 0;
        left: 30px;
        margin: 0 auto;
      }

      &:nth-child(2) {
        right: 0;
        top: 25px;
        z-index: 1;
      }

      &:nth-child(3) {
        right: 0;
        top: 275px;
        left: 65%;
        margin: 0 auto;
        z-index: 1;
      }

      &:nth-child(4) {
        top: 170px;
        z-index: 1;
        left: 20px;
      }

      &:nth-child(5) {
        left: 115px;
        top: 240px;
        z-index: 1;
      }

      &:nth-child(6) {
        right: 0;
        top: -75px;
        left: 9px;
        margin: 0 auto;
      }

      &:nth-child(7) {
        right: 0;
        top: 23px;
      }

      &:nth-child(8) {
        left: 0;
        top: -45px;
        z-index: -1;
      }

      &:nth-child(9) {
        right: 21%;
        top: 16px;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &.marketing-home {
    .hero-content {
      h1 {
        color: var(--blackColor);
      }

      p {
        color: var(--paragraphColor);
      }

      .video-btn {
        color: #716c80;

        &:hover,
        &:focus {
          i {
            color: var(--templateColor);
          }
        }
      }

      .btn {
        &:hover {
          &::after,
          &::before {
            background: var(--templateColor);
          }
        }
      }

      .btn-primary {
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > .btn-primary.dropdown-toggle {
          background: var(--templateColor);
          color: var(--whiteColor);
        }

        &:hover,
        &:focus {
          background: var(--templateColor);
          color: var(--whiteColor);
        }
      }
    }

    .marketing-image {
      position: relative;
      height: 100%;
      width: 100%;

      img {
        position: absolute;

        &:nth-child(1) {
          right: 0;
          top: -45px;
        }

        &:nth-child(2) {
          left: 90px;
          top: -30px;
        }

        &:nth-child(3) {
          left: 60px;
          top: 165px;
        }

        &:nth-child(4) {
          left: 0;
          right: -15px;
          text-align: center;
          margin: 0 auto;
          top: 185px;
        }

        &:nth-child(5) {
          bottom: -55px;
          right: 0;
        }

        &:nth-child(6) {
          bottom: -50px;
          right: 140px;
        }

        &:nth-child(7) {
          left: 198px;
          bottom: -55px;
        }

        &:nth-child(8) {
          left: 80px;
          bottom: -40px;
        }

        &:nth-child(9) {
          right: 65px;
          bottom: 0;
          animation: rotate3d 4s linear infinite;
        }

        &:nth-child(10) {
          left: 50px;
          top: 85px;
        }

        &:nth-child(11) {
          right: 195px;
          bottom: -55px;
        }

        &:nth-child(12) {
          top: 100px;
          right: -4px;
          left: 0;
          text-align: center;
          margin: 0 auto;
        }

        &:nth-child(13) {
          left: 62px;
          right: 0;
          margin: 0 auto;
          text-align: center;
          top: 20px;
          animation: rotate3d 6s linear infinite;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }

  &.product-home {
    .shape4 {
      left: auto;
      right: 20%;
      top: 25%;
    }
  }

  &.software-home {
    .bubble-animate {
      .circle {
        background: var(--whiteColor);
      }
    }
  }

  &.payment-processing-home {
    .creative-shape {
      img {
        width: 100%;
        height: 55px;
      }
    }
  }

  &.book-home {
    background: {
      position: center;
      size: cover;
      attachment: fixed;
      repeat: no-repeat;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--blackColor);
      z-index: -1;
      opacity: 0.87;
    }

    .hero-content {
      span {
        color: var(--whiteColor);
        display: inline-block;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 700;
        border-left: 3px solid var(--whiteColor);
        padding-left: 10px;
      }

      em {
        &.inlucdebooks {
          display: block;
          color: #f2f2f2;
          margin-top: 20px;
        }
      }
    }

    .book-image {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        position: absolute;
        bottom: -215px;
        right: 45px;
      }
    }
  }
}

.startup-home {
  .main-banner {
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--blackColor);
      z-index: -1;
      opacity: 0.95;
    }
  }

  &.owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        position: absolute;
        left: 10px;
        top: 50%;
        margin: 0;
        width: 45px;
        height: 45px;
        background: rgba(255, 255, 255, 0.1);
        line-height: 42px;
        font-size: 30px;
        border-radius: 5px;
        margin-top: -25px;
        transition: 0.4s;

        &.owl-next {
          left: auto;
          right: 10px;
        }

        &:hover {
          background: var(--templateColor);
        }
      }
    }
  }
}

.app-home {
  overflow: hidden;

  .shape4 {
    left: auto !important;
    right: 15%;
  }
}

.creative-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;

  img {
    width: 100%;
  }
}

.creative-shape-top {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;

  img {
    width: 100%;
  }
}

.creative-shape-relative {
  position: relative;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;

  img {
    width: 100%;
  }
}

.creative-shape-relative-top {
  position: relative;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  top: 0;

  img {
    width: 100%;
  }
}

#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
}

.shape-1 {
  left: 20px;
  bottom: 10px;
  height: 150px;
  width: 150px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
  animation: movebounce 5s linear infinite;
}

.shape1 {
  position: absolute;
  top: 85px;
  z-index: -1;
  left: 30px;
  animation: movebounce 5s linear infinite;
}

.shape2 {
  position: absolute;
  top: 85px;
  z-index: -1;
  right: 30px;
  animation: movebounce 5s linear infinite;
}

.shape3 {
  position: absolute;
  bottom: 85px;
  z-index: -1;
  right: 30px;
  animation: movebounce 5s linear infinite;
}

.shape4 {
  position: absolute;
  left: 9%;
  top: 23%;
  z-index: -1;
  opacity: 0.3;
}

.shape6 {
  position: absolute;
  top: 30%;
  z-index: -1;
  left: 30px;
  // animation: rotate3d 4s linear infinite;
  opacity: 0.6;
}

.shape7 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 0;
  animation: movebounce 5s linear infinite;
  opacity: 0.4;
}

.shape8 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 10%;
}

.shape9 {
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: -1;
  animation: animationFramesOne 15s infinite linear;
}

.shape10 {
  position: absolute;
  right: 5%;
  top: 8%;
  z-index: -1;
  animation: movebounce 5s linear infinite;
}

.shape11 {
  position: absolute;
  left: 3%;
  bottom: 3%;
  z-index: -1;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2),
      0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2),
      0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
  }

  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2),
      0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2),
      0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2),
      0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2),
      0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
  }

  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2),
      0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2),
      0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
  }
}

/*================================================
About CSS
=================================================*/
.about-content {
  .section-title {
    margin-bottom: 15px;
    text-align: left;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }

    p {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}

.about-inner-area {
  margin-top: 100px;

  .about-text {
    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 15px;
    }
  }
}

/*================================================
Features CSS
=================================================*/
.download-area {
  background-color: var(--templateColor);
}

.features-area {
  position: relative;
  z-index: 1;

  &.marketing-features {
    .features-inner-area {
      margin-top: 90px;
    }
  }

  &.software-features {
    .single-features {
      position: relative;
      text-align: left;
      padding-left: 85px;

      .icon {
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 65px;
        height: 65px;
        line-height: 65px;
        font-size: 25px;
      }

      h3 {
        margin-top: 0;
      }
    }
  }
}

.single-features {
  text-align: center;
  transition: 0.4s;
  margin-bottom: 40px;

  .icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 30px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: 25px 0 15px;
  }

  &:hover {
    transform: translateY(-10px);

    .icon {
      color: var(--whiteColor);
      background: var(--templateColor2);
      box-shadow: 0px 10px 30px 0px rgba(26, 38, 74, 0.14);
    }
  }
}

.single-holder-box {
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: var(--whiteColor);
  text-align: center;
  border-radius: 5px;
  padding: 30px;
  transition: 0.4s;

  h3 {
    font: {
      size: 20px;
      weight: 700;
    }

    margin: {
      top: 25px;
      bottom: 15px;
    }
  }

  img {
    width: 120px;
  }

  &:hover {
    transform: translateY(-7px);
  }

  &.mt-30 {
    margin-top: 30px;
  }

  &.mt-50 {
    margin-top: 50px;
  }
}

.features-holder-content {
  .section-title {
    text-align: left;

    margin: {
      bottom: 15px;
    }
  }

  .bar {
    margin: {
      left: 0;
      right: 0;
    }
  }

  p {
    margin: {
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  p {
    margin-bottom: 25px;
  }
}

.single-features-box {
  margin-bottom: 30px;
  padding: 30px;
  background: var(--whiteColor);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  transition: 0.4s;

  .icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #edf5ff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: 25px 0 15px;
  }

  &:hover {
    transform: translateY(-10px);

    .icon {
      color: var(--whiteColor);
      background: var(--templateColor2);
    }
  }
}

/*================================================
Product New Features CSS
=================================================*/
.new-features-area {
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.87;
  }
}

.new-features-content {
  .section-title {
    text-align: left;
    margin-bottom: 45px;

    h2 {
      color: var(--whiteColor);
    }

    .bar {
      margin: {
        bottom: 0;
        left: 0;
        right: 0;
      }

      background: var(--whiteColor);

      &::before {
        background: var(--templateColor);
      }
    }
  }

  .single-inner-features {
    position: relative;
    margin-bottom: 30px;
    padding-left: 45px;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 30px;
      color: var(--whiteColor);
    }

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 15px;
      color: var(--whiteColor);
    }

    p {
      color: #f2f2f2;
    }
  }
}

/*================================================
Services CSS
=================================================*/
.services-area {
  padding-bottom: 70px;
}

.single-services {
  margin-bottom: 30px;
  background: var(--whiteColor);

  padding: {
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
  }

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  transition: 0.4s;

  .icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 25px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: {
      top: 25px;
      bottom: 15px;
    }
  }

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: var(--templateColor);
      color: var(--whiteColor);
    }
  }
}

/*================================================
Book Discover CSS
=================================================*/
.discover-content {
  h3 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin-bottom: 15px;
  }

  span {
    display: block;
    color: var(--paragraphColor);

    font: {
      size: 20px;
      weight: 400;
    }

    margin-bottom: 15px;
    letter-spacing: 2px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: hidden;

    li {
      color: var(--paragraphColor);
      float: left;
      width: 45%;
      margin-top: 10px;

      i {
        color: var(--templateColor);
        margin-right: 2px;
      }
    }
  }
}

/*================================================
Overview CSS
=================================================*/
.overview-section {
  position: relative;
  z-index: 1;

  .shape7 {
    opacity: 1;
  }

  &.software-overview {
    &.bg-gray {
      background: var(--whiteColor);
    }
  }
}

.overview-box {
  margin-bottom: 60px;

  .overview-img {
    text-align: center;
  }

  .overview-content {
    .icon {
      background: #ffe9da;
      color: var(--templateColor2);
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-radius: 50%;
      font-size: 25px;
    }

    .overview-content__title {
      margin-bottom: 16px;

      span {
        font-style: normal;
        font-weight: 800;
        font-size: 54px;
        line-height: 140%;
        text-transform: uppercase;
        color: var(--primaryColor);
      }
    }

    .overview-content__main {
      margin-bottom: 40px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 150%;
        color: var(--darkColor);
      }

      .buycourse-title {
        span {
          font-style: normal;
          font-weight: 800;
          font-size: 54px;
          line-height: 140%;
          color: var(--whiteColor);
        }
      }
    }

    span {
      display: block;
      margin-bottom: -10px;
      color: var(--templateColor2);
    }

    h3 {
      position: relative;
      margin: 20px 0 15px;
      z-index: 1;

      font: {
        size: 23px;
        weight: 700;
      }
    }

    p {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 15px 0 25px;
      list-style-type: none;

      li {
        color: var(--paragraphColor);
        padding-left: 18px;
        position: relative;
        margin-bottom: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: var(--templateColor);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.features-list {
        overflow: hidden;

        margin: {
          top: 20px;
          bottom: 0;
        }

        li {
          float: left;
          width: 45%;
          background: var(--whiteColor);
          padding: 10px 20px 10px 30px;
          border-radius: 5px;

          margin: {
            bottom: 0;
            right: 10px;
            top: 10px;
          }

          font-size: 15px;
          border: 1px solid #f9f6f6;

          &::before {
            left: 15px;
            top: 16px;
          }
        }
      }
    }
  }

  &.modern-flexible {
    .single-item {
      position: relative;
      padding-left: 55px;
      margin-bottom: 30px;

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 30px;
        color: var(--templateColor);
      }

      h3 {
        font: {
          size: 23px;
          weight: 700;
        }

        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.right-icon {
        padding: {
          left: 0;
          right: 55px;
        }

        text-align: right;

        .icon {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .app-fetured-item {
    position: relative;
    height: 100%;

    .app-item {
      text-align: center;
      width: 200px;
      padding: 45px 10px;
      border-radius: 5px;
      animation: movebounce 5s linear infinite;
      position: absolute;

      &.item-one {
        background-image: -webkit-linear-gradient(
          40deg,
          #e87e16 0,
          #f0de14 100%
        );
        box-shadow: 0 20px 40px 0 rgba(224, 149, 32, 0.4);
        right: 0;
        top: -150px;
        z-index: 1;
      }

      &.item-two {
        background-image: -webkit-linear-gradient(
          40deg,
          #13c4a1 0,
          #41cbb0 100%
        );
        box-shadow: 0 20px 40px 0 rgba(19, 196, 161, 0.4);
        left: 60px;
        top: 0;
      }

      &.item-three {
        background-image: -webkit-linear-gradient(
          40deg,
          #008000 0,
          #268b26 100%
        );
        box-shadow: 0 20px 40px 0 rgba(0, 128, 0, 0.4);
        left: 90px;
        bottom: -10px;
      }

      &.item-four {
        background-image: -webkit-linear-gradient(
          40deg,
          #ff8a73 0,
          #f89b88 100%
        );
        box-shadow: 0 20px 40px 0 rgba(255, 138, 115, 0.4);
        right: 35px;
        top: -15px;
      }

      i {
        font-size: 40px;
        color: var(--whiteColor);
      }

      h6 {
        font: {
          size: 16px;
          weight: 700;
        }

        color: var(--whiteColor);

        margin: {
          top: 10px;
          bottom: 0;
        }
      }
    }
  }
}

.buycourse-box {
  margin-bottom: 60px;

  .buycourse-img {
    text-align: center;
  }

  .buycourse-content {
    position: relative;

    .buycourse-title {
      margin-bottom: 26px;

      span {
        font-style: normal;
        font-weight: 800;
        font-size: 54px;
        line-height: 140%;
        color: var(--whiteColor);
      }
    }

    .buycourse-arrow {
      position: absolute;
      right: -20px;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
    }

    .buycourse-action {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    span {
      display: block;
      margin-bottom: -10px;
      color: var(--templateColor2);
    }

    h3 {
      position: relative;
      margin: 20px 0 15px;
      z-index: 1;

      font: {
        size: 23px;
        weight: 700;
      }
    }

    p {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 15px 0 25px;
      list-style-type: none;

      li {
        color: var(--paragraphColor);
        padding-left: 18px;
        position: relative;
        margin-bottom: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: var(--templateColor);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.features-list {
        overflow: hidden;

        margin: {
          top: 20px;
          bottom: 0;
        }

        li {
          float: left;
          width: 45%;
          background: var(--whiteColor);
          padding: 10px 20px 10px 30px;
          border-radius: 5px;

          margin: {
            bottom: 0;
            right: 10px;
            top: 10px;
          }

          font-size: 15px;
          border: 1px solid #f9f6f6;

          &::before {
            left: 15px;
            top: 16px;
          }
        }
      }
    }
  }

  &.modern-flexible {
    .single-item {
      position: relative;
      padding-left: 55px;
      margin-bottom: 30px;

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 30px;
        color: var(--templateColor);
      }

      h3 {
        font: {
          size: 23px;
          weight: 700;
        }

        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.right-icon {
        padding: {
          left: 0;
          right: 55px;
        }

        text-align: right;

        .icon {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .app-fetured-item {
    position: relative;
    height: 100%;

    .app-item {
      text-align: center;
      width: 200px;
      padding: 45px 10px;
      border-radius: 5px;
      animation: movebounce 5s linear infinite;
      position: absolute;

      &.item-one {
        background-image: -webkit-linear-gradient(
          40deg,
          #e87e16 0,
          #f0de14 100%
        );
        box-shadow: 0 20px 40px 0 rgba(224, 149, 32, 0.4);
        right: 0;
        top: -150px;
        z-index: 1;
      }

      &.item-two {
        background-image: -webkit-linear-gradient(
          40deg,
          #13c4a1 0,
          #41cbb0 100%
        );
        box-shadow: 0 20px 40px 0 rgba(19, 196, 161, 0.4);
        left: 60px;
        top: 0;
      }

      &.item-three {
        background-image: -webkit-linear-gradient(
          40deg,
          #008000 0,
          #268b26 100%
        );
        box-shadow: 0 20px 40px 0 rgba(0, 128, 0, 0.4);
        left: 90px;
        bottom: -10px;
      }

      &.item-four {
        background-image: -webkit-linear-gradient(
          40deg,
          #ff8a73 0,
          #f89b88 100%
        );
        box-shadow: 0 20px 40px 0 rgba(255, 138, 115, 0.4);
        right: 35px;
        top: -15px;
      }

      i {
        font-size: 40px;
        color: var(--whiteColor);
      }

      h6 {
        font: {
          size: 16px;
          weight: 700;
        }

        color: var(--whiteColor);

        margin: {
          top: 10px;
          bottom: 0;
        }
      }
    }
  }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
  position: relative;
  z-index: 1;
  padding: 60px 0;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.87;
  }

  p {
    color: var(--whiteColor);

    font: {
      size: 25px;
      weight: 700;
    }
  }

  .btn {
    &:hover {
      &::after,
      &::before {
        background: var(--whiteColor);
      }
    }
  }

  .btn-primary {
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      color: var(--templateColor2);
      background: var(--whiteColor);
    }

    &:hover,
    &:focus {
      background: var(--whiteColor);
      color: var(--templateColor2);
    }
  }
}

/*================================================
SAAS Tools CSS
=================================================*/
.saas-tools {
  position: relative;
  z-index: 1;

  .bubble-animate {
    .circle {
      &.small {
        opacity: 0.1;
      }

      &.medium {
        opacity: 0.1;
      }
    }
  }
}

.tab {
  overflow: hidden;
  background: var(--whiteColor);
  padding: 30px;
  box-shadow: 0 6px 13px 0 rgba(137, 173, 255, 0.35);
  border-radius: 5px;

  .tabs {
    list-style-type: none;
    margin-bottom: 30px;

    padding: {
      left: 60px;
      right: 60px;
    }

    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;

    li {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;

      padding: {
        right: 15px;
        left: 15px;
      }

      text-align: center;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        font: {
          size: 18px;
          weight: 700;
        }

        text-decoration: none;
        display: inline-block;

        i {
          display: inline-block;
          width: 60px;
          height: 60px;
          line-height: 60px;
          background: #ffe9da;
          color: var(--templateColor2);
          text-align: center;
          font-size: 25px;
          border-radius: 50%;
          margin-bottom: 15px;
          -moz-transition: 0.4s;
          transition: 0.4s;
        }

        &:hover {
          i {
            color: var(--whiteColor);
            background: var(--templateColor);
          }

          color: var(--templateColor);
        }
      }

      &.current {
        i {
          color: var(--whiteColor);
          background: var(--templateColor);
        }

        a {
          color: var(--templateColor);
        }
      }
    }
  }

  .tab_content {
    .tabs_item {
      display: none;

      &:first-child {
        display: block;
      }

      .tabs_item_content {
        h3 {
          font: {
            size: 23px;
            weight: 700;
          }

          margin-bottom: 15px;
        }

        p {
          margin-bottom: 0;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          margin-top: 15px;

          li {
            margin-top: 10px;
            font-weight: 420;
            color: var(--paragraphColor);

            &:first-child {
              margin-top: 0;
            }

            i {
              margin-right: 3px;
              font-size: 14px;
              color: var(--templateColor);
            }
          }
        }
      }
    }
  }
}

/*================================================
Fun Facts CSS
=================================================*/
.single-funfact {
  position: relative;
  text-align: center;
  border: 1px solid #edf5ff;

  padding: {
    left: 30px;
    top: 30px;
    bottom: 30px;
    right: 30px;
  }

  transition: 0.4s;
  border-radius: 5px;

  .icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 25px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin: 25px 0 8px;
  }

  p {
    font-size: 17px;
  }

  &:hover {
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
    transform: translate(0, -10px);
    background-color: var(--whiteColor);

    .icon {
      background: var(--templateColor);
      color: var(--whiteColor);
    }
  }
}

.single-funfact-box {
  position: relative;
  padding-left: 90px;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    text-align: center;
    height: 65px;
    line-height: 65px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 25px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin: 0 0 6px;
    color: var(--whiteColor);
  }

  p {
    font-size: 17px;
    color: var(--whiteColor);
  }

  &:hover {
    .icon {
      background: var(--templateColor2);
      color: var(--whiteColor);
    }
  }
}

.fun-facts-inner-area {
  margin-top: 100px;

  .fun-facts-inner-content {
    max-width: 540px;

    h3 {
      font: {
        size: 35px;
        weight: 700;
      }

      margin: 0 0 15px;
    }

    p {
      margin-bottom: 15px;
    }

    ul {
      padding: 0;
      margin: 0 0 25px;
      list-style-type: none;

      li {
        color: var(--paragraphColor);
        padding-left: 18px;
        position: relative;
        margin-bottom: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: var(--templateColor);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.app-funfacts-area {
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  padding: {
    bottom: 70px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.87;
  }

  .single-funfact {
    border: none;
    padding: 0;
    margin-bottom: 30px;

    h3 {
      color: var(--whiteColor);
    }

    p {
      color: var(--whiteColor);
    }

    &:hover {
      background: transparent;
      box-shadow: unset;

      .icon {
        background: var(--templateColor2);
        color: var(--whiteColor);
      }
    }
  }
}

/*================================================
Work Process CSS
=================================================*/
.saas-work-process {
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.87;
  }

  .section-title {
    h2,
    p {
      color: var(--whiteColor);
    }

    .bar {
      background: var(--whiteColor);

      &::before {
        background: var(--templateColor);
      }
    }
  }
}

.single-work-process {
  position: relative;
  padding: 30px;
  background: var(--whiteColor);
  border-radius: 3px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  transition: 0.4s;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--whiteColor);
    width: 96%;
    opacity: 0.62;
    height: 50%;
    bottom: -6px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 3px;
    transition: 0.4s;
  }

  .icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 25px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: 25px 0 15px;
  }

  &:hover {
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
    transform: translate(0, -10px);
    background-color: var(--whiteColor);

    .icon {
      background: var(--templateColor);
      color: var(--whiteColor);
    }

    &::before {
      bottom: -5px;
    }
  }
}

/*================================================
Pricing CSS
=================================================*/
.pricing-area {
  position: relative;
  z-index: 1;

  &.book-pricing {
    .pricing-table {
      .price-footer {
        margin-top: 30px;
      }
    }
  }
}

.pricingTable {
  text-align: center;
  background: var(--whiteColor);
  padding: 50px 0 30px;
  margin-top: 40px;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease 0s;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

  .pricing-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    line-height: 73px;
    background: var(--whiteColor);
    color: var(--templateColor2);
    position: absolute;
    top: -40px;
    left: 50%;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    font-size: 30px;
    transform: translateX(-50%);
    transition: all 0.5s ease 0s;
  }

  .price-Value {
    display: inline-block;

    font: {
      size: 45px;
      weight: 700;
    }

    position: relative;

    .currency {
      font: {
        size: 18px;
        weight: 900;
      }

      position: absolute;
      top: 3px;
      left: -13px;
    }
  }

  .month {
    display: inline-block;

    font: {
      size: 15px;
      weight: 700;
    }

    padding-left: 10px;
    text-transform: capitalize;
  }

  .pricingHeader {
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--templateColor);
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
      transition: all 0.5s ease 0s;
    }

    .title {
      width: 50%;
      background: var(--templateColor);
      margin: 15px auto 10px;
      padding: 10px 0;

      font: {
        size: 18px;
        weight: 700;
      }

      color: var(--whiteColor);
      text-transform: uppercase;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      transition: all 0.5s ease 0s;
    }
  }

  .pricing-content {
    margin: {
      top: 30px;
      bottom: 35px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 10px;
        text-decoration: line-through;
        color: #ccdcea;

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          text-decoration: none;
          color: var(--paragraphColor);
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
    transform: translate(0, -10px);

    .pricing-icon {
      background: var(--templateColor);
      color: var(--whiteColor);
    }

    .pricingHeader {
      &::after {
        background: var(--templateColor2);
      }

      .title {
        color: var(--whiteColor);
        background: var(--templateColor2);
      }
    }
  }
}

/* Pricing Table Style Two */
.pricing-table {
  text-align: center;
  padding: 30px 0;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  position: relative;
  z-index: 1;
  background-color: var(--templateColor);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

  .price-header {
    .title {
      color: var(--whiteColor);

      font: {
        size: 23px;
        weight: 700;
      }

      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .price {
      font: {
        size: 45px;
        weight: 700;
      }

      transition: 0.3s ease-in;
      text-align: left;
      position: relative;
      padding: 15px 0 15px 65px;
      z-index: 1;
      letter-spacing: 2px;

      &::after {
        position: absolute;
        left: -20%;
        top: 0;
        width: 100%;
        background-color: var(--whiteColor);
        border-radius: 25px;
        content: "";
        height: 100%;
        -webkit-transform: skewX(-35deg);
        -ms-transform: skewX(-35deg);
        transform: skewX(-35deg);
        z-index: -1;
      }

      .dollar {
        font: {
          size: 18px;
          weight: 900;
        }

        position: relative;
        top: -25px;
      }

      .month {
        font: {
          size: 18px;
          weight: 700;
        }
      }
    }
  }

  .price-body {
    ul {
      margin: 30px 0;
      padding: 0;
      list-style-type: none;

      li {
        color: var(--whiteColor);
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .price-footer {
    .btn {
      &:hover {
        &::after,
        &::before {
          background: var(--whiteColor);
        }
      }
    }

    .btn-primary {
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > .btn-primary.dropdown-toggle {
        color: var(--templateColor2);
        background: var(--whiteColor);
      }

      &:hover,
      &:focus {
        background: var(--whiteColor);
        color: var(--templateColor2);
      }
    }
  }

  &:hover {
    transform: translateY(-7px);
  }
}

/* Pricing Table Style Three */
.pricing-table-box {
  padding-bottom: 30px;
  background: var(--whiteColor);
  text-align: center;
  z-index: 1;
  position: relative;
  border-radius: 5px;

  background: {
    position: center center;
  }

  overflow: hidden;
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  transition: 0.4s;

  .pricingTable-header {
    padding: 30px 15px 45px;
    background: var(--templateColor);
    clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
    position: relative;

    &::before {
      content: "";
      width: 400px;
      height: 400px;
      border-radius: 50%;
      position: absolute;
      right: -50%;
      top: -130%;
      background: repeating-radial-gradient(
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.2) 20%
      );
      transition: 0.4s;
    }
  }

  .title {
    font: {
      size: 23px;
      weight: 700;
    }

    color: var(--whiteColor);
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .price-value {
    display: block;

    font: {
      size: 45px;
      weight: 700;
    }

    color: var(--whiteColor);
    margin-bottom: 30px;

    span {
      font-size: 15px;
      text-transform: uppercase;
      margin-left: -10px;
    }

    sup {
      font-size: 23px;
      top: -25px;
    }
  }

  .pricing-content {
    padding: 30px 25px;
    margin-bottom: 0;
    list-style-type: none;

    li {
      color: #828893;
      text-transform: capitalize;
      border-bottom: 1px solid #eee;
      margin-bottom: 12px;
      padding-bottom: 12px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    transform: translateY(-10px);

    .pricingTable-header {
      &::before {
        right: 50%;
      }
    }
  }
}

/*================================================
Project CSS
=================================================*/
.project-area {
  position: relative;
  z-index: 1;

  .owl-theme {
    .owl-nav {
      margin-top: 30px;

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 3px 5px 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        border: 1px solid #eee;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

.single-project {
  text-align: center;
  background: var(--whiteColor);
  box-shadow: 0 10px 50px 0 rgba(46, 65, 90, 0.1);
  margin-bottom: 30px;
  border-radius: 5px;

  .project-content {
    text-align: left;
    padding: 30px;

    h3 {
      font: {
        size: 20px;
        weight: 700;
      }

      margin: 0;
    }

    span {
      display: block;
      margin-bottom: 10px;
      color: var(--templateColor2);
      font-size: 15px;
    }
  }

  .project-image {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--blackColor);
      opacity: 0;
      visibility: hidden;
      border-radius: 5px;
      transform: scale(0.8);
      transition: 0.4s;
    }

    .popup-btn {
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      margin: 0 auto;
      font-size: 30px;
      color: var(--whiteColor);
      opacity: 0;
      visibility: hidden;
      margin-top: -20px;
      transition: 0.4s;
      transform: scale(0);
      cursor: pointer;

      &:hover {
        color: var(--templateColor);
      }
    }

    img {
      transform-style: unset !important;
      border-radius: 5px;
    }
  }

  &:hover {
    .project-image {
      &::before {
        visibility: visible;
        opacity: 0.8;
        transform: scale(1);
      }

      .popup-btn {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/*================================================
Project Details CSS
=================================================*/
.project-details-image {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--blackColor);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
  }

  .popup-youtube,
  .popup-btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    color: var(--whiteColor);
    font-size: 30px;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    &::before {
      opacity: 0.77;
      visibility: visible;
    }

    .popup-youtube,
    .popup-btn {
      opacity: 1;
      visibility: visible;
      margin-top: -20px;
    }
  }
}

.project-details-desc {
  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: {
      bottom: 15px;
      top: 25px;
    }
  }

  p {
    margin-bottom: 15px;
  }

  .project-details-information {
    overflow: hidden;

    .single-info-box {
      float: left;
      width: 20%;
      margin-top: 30px;

      h4 {
        font: {
          size: 23px;
          weight: 700;
        }

        margin-bottom: 15px;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          display: inline-block;

          a {
            margin-right: 10px;
            color: var(--paragraphColor);

            &:hover {
              color: var(--templateColor);
            }
          }
        }
      }
    }
  }
}

/*================================================
Gallery CSS
=================================================*/
.single-image {
  text-align: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--blackColor);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.8);
    transition: 0.4s;
  }

  .popup-btn {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    margin: 0 auto;
    font-size: 30px;
    color: var(--whiteColor);
    opacity: 0;
    visibility: hidden;
    margin-top: -20px;
    transition: 0.4s;
    transform: scale(0);
    cursor: pointer;

    &:hover {
      color: var(--templateColor);
    }
  }

  &:hover {
    &::before {
      visibility: visible;
      opacity: 0.8;
      transform: scale(1);
    }

    .popup-btn {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}

/*================================================
Get Started CSS
=================================================*/
.get-started {
  position: relative;
  z-index: 1;
}

.get-started-form {
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 40px;
  text-align: center;
  border-radius: 3px;

  form {
    .btn {
      display: block;
      width: 100%;
      letter-spacing: 2px;
    }

    p {
      margin-top: 15px;

      a {
        color: #50a1ff;

        &:hover {
          color: var(--templateColor);
        }
      }
    }
  }
}

/*================================================
How App Works CSS
=================================================*/
.how-app-work {
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.87;
  }

  .section-title {
    h2,
    p {
      color: var(--whiteColor);
    }

    .bar {
      background: var(--whiteColor);

      &::before {
        background: var(--templateColor);
      }
    }
  }

  .app-mobile {
    text-align: center;
    margin-bottom: 60px;
    animation: movebounce 5s linear infinite;
  }
}

.single-app-works {
  text-align: center;
  transition: 0.4s;

  .icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #edf5ff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    margin: {
      bottom: 15px;
      top: 25px;
    }

    font: {
      size: 23px;
      weight: 700;
    }

    color: var(--whiteColor);
  }

  p {
    color: var(--whiteColor);
  }

  &:hover {
    transform: translateY(-7px);

    .icon {
      background: var(--templateColor2);
      color: var(--whiteColor);
    }
  }
}

/*================================================
Screenshot CSS
=================================================*/
.screenshot-slides {
  &.owl-theme {
    .owl-nav {
      margin-top: 120px;

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 3px 5px 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        border: 1px solid #eee;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

.screenshot-contain {
  position: relative;
  margin-top: 82px;

  .mobile-light {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    width: auto;
    height: auto;
    top: -82px;
    z-index: 2;
  }

  .screenshot-item {
    img {
      transform-style: unset !important;
      width: unset !important;
      margin: auto;
    }
  }
}

.page-from-book {
  .owl-theme {
    .owl-nav {
      margin-top: 50px;
    }
  }

  .screenshot-item {
    position: relative;
    text-align: center;

    a {
      display: block;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--blackColor);
      opacity: 0;
      visibility: hidden;
      transform: scale(0.8);
      transition: 0.4s;
    }

    .popup-btn {
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      margin: 0 auto;
      font-size: 30px;
      color: var(--whiteColor);
      opacity: 0;
      display: inline-block;
      visibility: hidden;
      margin-top: -20px;
      transition: 0.4s;
      transform: scale(0);

      &:hover {
        color: var(--templateColor);
      }
    }

    img {
      transform-style: unset !important;
      width: unset !important;
    }

    &:hover {
      &::before {
        visibility: visible;
        opacity: 0.8;
        transform: scale(1);
      }

      .popup-btn {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/*================================================
Team CSS
=================================================*/
.team-area {
  padding-bottom: 70px;
}

.team-slides {
  &.owl-theme {
    .owl-nav {
      margin: {
        top: 20px;
        bottom: 30px;
      }

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 3px 5px 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        border: 1px solid #eee;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

.single-team-member {
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin-bottom: 30px;
  background: var(--whiteColor);
  text-align: center;
  border-radius: 5px;

  img {
    border-radius: 5px 5px 0 0;
  }

  .team-content {
    padding: 30px;

    h3 {
      font: {
        size: 20px;
        weight: 700;
      }

      margin-bottom: 8px;
    }

    span {
      display: block;
      color: var(--paragraphColor);
      font-size: 15px;
    }

    ul {
      padding: 0;
      margin: 15px 0 0;
      list-style-type: none;

      li {
        display: inline-block;

        a {
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border: 1px solid var(--paragraphColor);
          border-radius: 50%;
          font-size: 14px;
          margin: 0 2px;
          color: var(--paragraphColor);

          &:hover {
            color: var(--whiteColor);
            background: var(--templateColor);
            border-color: var(--templateColor);
          }

          &.facebook {
            border-color: #5580ff;
            color: #5580ff;

            &:hover {
              background-color: #5580ff;
              color: var(--whiteColor);
            }
          }

          &.instagram {
            border-color: var(--blackColor);
            color: var(--blackColor);

            &:hover {
              background-color: var(--blackColor);
              color: var(--whiteColor);
            }
          }

          &.twitter {
            border-color: #ff8a73;
            color: #ff8a73;

            &:hover {
              background-color: #ff8a73;
              color: var(--whiteColor);
            }
          }

          &.linkedin {
            border-color: #ffd474;
            color: #ffd474;

            &:hover {
              background-color: #ffd474;
              color: var(--whiteColor);
            }
          }
        }
      }
    }
  }
}

.single-team-member-box {
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin-bottom: 30px;
  background: var(--whiteColor);
  text-align: center;
  border-radius: 5px;
  padding: 30px;

  img {
    clip-path: polygon(
      50% 0%,
      38.11% 6.7%,
      24.99% 6.72%,
      18.06% 18.44%,
      6.7% 25%,
      6.56% 38.64%,
      0% 50%,
      6.7% 61.89%,
      6.7% 75%,
      18.44% 81.94%,
      25% 93.3%,
      38.64% 93.44%,
      50% 100%,
      61.88% 93.3%,
      75% 93.3%,
      81.94% 81.56%,
      93.3% 74.9%,
      93.44% 61.36%,
      100% 50%,
      93.3% 38.11%,
      93.3% 25%,
      81.56% 18.06%,
      74.96% 6.7%,
      61.36% 6.56%,
      50% 0%
    );
  }

  .team-content {
    margin-top: 30px;

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 8px;
    }

    span {
      display: block;
      color: var(--paragraphColor);
      font-size: 15px;
    }

    ul {
      padding: 0;
      margin: 15px 0 0;
      list-style-type: none;

      li {
        display: inline-block;

        a {
          font-size: 18px;
          color: var(--paragraphColor);
          margin: 0 4px;

          &:hover {
            transform: translateY(-5px);
          }

          &.facebook {
            color: #5580ff;
          }

          &.twitter {
            color: #ff8a73;
          }

          &.linkedin {
            color: #ffd474;
          }

          &.instagram {
            color: var(--blackColor);
          }
        }
      }
    }
  }
}

/*================================================
Next Generation CSS
=================================================*/
.next-generation-virtual-reality {
  padding-bottom: 60px;

  .single-item {
    margin-bottom: 40px;
    position: relative;
    padding-left: 55px;

    .icon {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 35px;
      color: var(--templateColor);
      transition: 0.4s;
    }

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 15px;
    }

    &:hover {
      .icon {
        color: var(--templateColor2);
      }
    }
  }
}

/*================================================
Feedback CSS
=================================================*/
.feedback-area {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .svg-feedback-bottom {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}

.single-feedback {
  position: relative;
  padding: 30px;
  background: var(--whiteColor);
  transition: 0.4s;
  text-align: center;
  border-radius: 3px;
  z-index: 1;

  margin: {
    bottom: 30px;
    top: 50px;
  }

  .client-info {
    img {
      clip-path: polygon(
        50% 0%,
        38.11% 6.7%,
        24.99% 6.72%,
        18.06% 18.44%,
        6.7% 25%,
        6.56% 38.64%,
        0% 50%,
        6.7% 61.89%,
        6.7% 75%,
        18.44% 81.94%,
        25% 93.3%,
        38.64% 93.44%,
        50% 100%,
        61.88% 93.3%,
        75% 93.3%,
        81.94% 81.56%,
        93.3% 74.9%,
        93.44% 61.36%,
        100% 50%,
        93.3% 38.11%,
        93.3% 25%,
        81.56% 18.06%,
        74.96% 6.7%,
        61.36% 6.56%,
        50% 0%
      );
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;
      box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
      margin: 0 auto;
      width: 115px !important;
      height: 115px !important;
    }

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin: {
        bottom: 5px;
        top: 55px;
      }
    }

    span {
      color: var(--templateColor2);
      font-size: 15px;
    }
  }

  p {
    margin-top: 25px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background: var(--templateColor);
    border-radius: 0 0 3px 3px;
    transition: 0.4s;
  }

  &:hover {
    box-shadow: 0 0 20px 5px rgba(137, 173, 255, 0.35);
    background-color: var(--whiteColor);
  }
}

.single-feedback-box {
  background: var(--whiteColor);
  box-shadow: 0 10px 50px 0 rgba(46, 65, 90, 0.1);

  margin: {
    bottom: 30px;
    left: 30px;
  }

  border-radius: 5px;
  position: relative;
  padding: 30px 30px 30px 45px;

  .client-info {
    img {
      position: absolute;
      left: -30px;
      width: 60px !important;
      height: 60px;
      top: 30px;
      border-radius: 50%;
      border: 3px solid var(--whiteColor);
    }

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin: {
        bottom: 5px;
      }
    }

    span {
      color: var(--templateColor2);
      font-size: 15px;
    }
  }

  p {
    margin-top: 20px;
  }
}

.feedback-slides {
  .owl-item {
    .single-feedback {
      opacity: 0.5;
      transition: 0.5s;

      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.active {
      &.center {
        .single-feedback {
          opacity: 1;
          transition: 0.5s;
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

          &::before {
            opacity: 1;
            visibility: visible;
            width: 100%;
          }
        }

        .single-feedback-box {
          margin-top: 30px;
        }
      }
    }
  }

  &.owl-theme {
    .owl-nav {
      margin-top: 20px;

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 3px 5px 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        border: 1px solid #eee;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

/*================================================
App Download CSS
=================================================*/
.app-download {
  position: relative;
  z-index: 1;

  .section-title {
    text-align: left;
    margin-bottom: 0;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }

    p {
      margin: {
        left: 0;
        right: 0;
        bottom: 35px;
      }
    }

    .download-btn {
      a {
        position: relative;
        padding: 12px 35px 12px 65px;
        background: var(--templateColor2);
        color: var(--whiteColor);
        border-radius: 70px;

        font: {
          weight: 300;
          size: 15px;
        }

        margin-right: 10px;
        box-shadow: 0 13px 27px 0 rgba(163, 48, 53, 0.25);

        &:last-child {
          margin-right: 0;
        }

        i {
          position: absolute;
          left: 25px;
          top: 50%;
          font-size: 25px;
          margin-top: -12px;
        }

        span {
          display: block;

          font: {
            weight: 700;
            size: 16px;
          }
        }

        &:hover,
        &:focus {
          background: var(--templateColor);
          transform: translateY(-5px);
        }
      }
    }
  }

  .download-image {
    position: absolute;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    bottom: 295px;

    img {
      animation: movebounce 5s linear infinite;
      position: absolute;
      left: -75px;
      top: 0;
      width: 445px;

      &:last-child {
        left: 225px;
        top: 25px;
      }
    }
  }

  .col-lg-6,
  .col-md-12 {
    position: relative;
  }
}

/*================================================
Platform Connect CSS
=================================================*/
.platform-connect {
  h2 {
    text-align: center;
    max-width: 520px;

    font: {
      size: 35px;
      weight: 700;
    }

    margin: {
      bottom: 60px;
      left: auto;
      right: auto;
    }
  }

  .box {
    display: block;
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
    position: relative;
    padding: 15px 15px 15px 80px;
    border-radius: 5px;
    transition: 0.4s;
    background: var(--whiteColor);

    img {
      position: absolute;
      left: 15px;
      top: 15px;
    }

    h3 {
      font: {
        size: 20px;
        weight: 700;
      }

      margin-bottom: 5px;
    }

    span {
      text-transform: uppercase;
      color: var(--paragraphColor);
      font-size: 14px;
    }

    i {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -8px;
      color: var(--paragraphColor);
      transition: 0.4s;
      font-size: 25px;
    }

    &:hover {
      transform: translateY(-10px);

      i {
        color: var(--templateColor);
      }
    }
  }
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
  &.blog-section {
    background: unset;
  }

  .owl-theme {
    .owl-nav {
      margin-top: 20px;

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 3px 5px 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        border: 1px solid #eee;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

.single-blog-post {
  background: var(--whiteColor);
  margin-bottom: 30px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  border-radius: 20px;

  .post-image {
    display: block;

    img {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
  }

  .blog-post-content {
    padding: 16px;

    ul {
      padding: 0;
      list-style-type: none;
      margin: 0;

      li {
        display: inline-block;
        margin-right: 10px;
        font-size: 15px;
        color: var(--paragraphColor);

        &:last-child {
          margin-right: 0;
        }

        i {
          color: var(--templateColor);
          margin-right: 4px;
        }

        a {
          color: var(--paragraphColor);

          &:hover {
            color: var(--templateColor);
          }
        }
      }
    }

    h3 {
      margin: {
        top: 15px;
        bottom: 15px;
      }

      font: {
        size: 20px;
        weight: 700;
      }

      line-height: 30px;
    }

    p {
      margin-bottom: 20px;
    }

    .read-more-btn {
      font-weight: 700;
    }
  }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details-area {
  position: relative;

  .blog-details {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    background: var(--whiteColor);

    .article-img {
      position: relative;

      > img {
        width: 100%;
      }

      .date {
        position: absolute;
        bottom: 15px;
        width: 100px;
        right: 15px;
        height: 100px;
        text-align: center;

        font: {
          size: 25px;
          style: italic;
          weight: 700;
        }

        background: var(--templateColor);
        color: var(--whiteColor);
        border-radius: 5px;
        padding-top: 19px;
        line-height: 32px;
      }
    }

    .article-content {
      padding: 30px;

      ul {
        li {
          display: inline-block;
        }

        &.category {
          li {
            margin-right: 5px;
            margin-bottom: 10px;

            a {
              background: var(--templateColor);
              color: var(--whiteColor);
              padding: 5px 15px 3px;
              display: inline-block;
              border-radius: 3px;
              font-weight: 420;
            }
          }

          margin-bottom: 20px;
        }
      }

      h3 {
        font: {
          size: 23px;
          weight: 700;
        }

        margin-bottom: -5px;
      }

      p {
        margin-bottom: 0;
        margin-top: 20px;
      }

      .blockquote {
        margin: {
          bottom: 20px;
          top: 20px;
        }

        background: #f4f4f4;
        padding: 30px;
        border-left: 4px solid var(--templateColor);

        p {
          margin-top: 0;
          font-size: 18px;
        }
      }

      .share-post {
        text-align: center;
        margin-top: 30px;

        ul {
          li {
            margin: 0 2px;

            a {
              width: 40px;
              font-size: 15px;
              height: 40px;
              display: inline-block;
              line-height: 38px;
              border: 1px solid #eeeeee;
              border-radius: 50%;
              color: #555555;

              &:hover,
              &:focus {
                color: var(--whiteColor);
                border-color: var(--templateColor);
                background-color: var(--templateColor);
              }
            }
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
      }
    }
  }

  .post-controls-buttons {
    overflow: hidden;

    margin: {
      top: 25px;
      bottom: 25px;
    }

    background: var(--whiteColor);
    padding: 30px;
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);

    .controls-left {
      float: left;
    }

    .controls-right {
      float: right;
    }

    div {
      a {
        border: 1px solid #eeeeee;
        padding: 8px 20px;
        display: inline-block;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;

        &:hover,
        &:focus {
          color: var(--whiteColor);
          background: var(--templateColor);
          border-color: var(--templateColor);
        }
      }
    }
  }

  .post-comments {
    padding: 30px;
    background: var(--whiteColor);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 0;
    }

    .single-comment {
      position: relative;
      padding-left: 95px;
      margin-top: 30px;

      .comment-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 75px;
        height: 75px;

        img {
          border-radius: 50%;
        }
      }

      .comment-content {
        h4 {
          font: {
            size: 15px;
            weight: 700;
          }

          margin-bottom: 10px;
          text-transform: uppercase;
        }

        span {
          color: var(--paragraphColor);
        }

        p {
          margin-bottom: 15px;
        }

        a {
          position: absolute;
          font-weight: 700;
          right: 0;
          bottom: 0;
          text-transform: uppercase;
        }
      }

      &.left-m {
        margin-left: 85px;
      }
    }
  }

  .leave-a-reply {
    padding: 30px;
    background: var(--whiteColor);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    margin-top: 25px;

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin-bottom: 30px;
    }
  }
}

/*================================================
Boxes CSS
=================================================*/
.boxes-area {
  position: relative;
  margin-top: -140px;

  .single-box {
    padding: 30px;
    background: var(--whiteColor);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    transition: 0.4s;

    .icon {
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      background: #edf5ff;
      border-radius: 50%;
      text-align: center;
      font-size: 25px;
      color: var(--templateColor);
      transition: 0.4s;
    }

    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      margin: 25px 0 15px;
    }

    &:hover {
      transform: translateY(-10px);

      .icon {
        color: var(--whiteColor);
        background: var(--templateColor2);
      }
    }
  }
}

/*================================================
Join CSS
=================================================*/
.join-content {
  span {
    display: block;
    color: var(--templateColor2);
    margin-bottom: 10px;

    font: {
      size: 14px;
    }

    text-transform: uppercase;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 15px;
  }

  p {
    margin-bottom: 25px;
  }
}

/*================================================
Analysis CSS
=================================================*/
.analysis-form {
  background: var(--whiteColor);
  box-shadow: 5px 7px 30px rgba(72, 73, 121, 0.15);
  padding: 40px;
  border-radius: 5px;
  text-align: center;

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 30px;
  }

  form {
    .btn {
      display: block;
      width: 100%;
      padding: 13px 40px;
      border-radius: 5px;
    }
  }
}

.analysis-text {
  span {
    color: var(--templateColor2);
    text-transform: capitalize;
  }

  h3 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin: 15px 0 20px;
    line-height: 40px;
  }

  ul {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;

    li {
      position: relative;
      color: var(--paragraphColor);
      margin-bottom: 15px;
      padding-left: 30px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 17px;
        height: 17px;
        background: var(--templateColor);
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
  border-top: 1px solid #edf5ff;

  .item {
    text-align: center;

    a {
      display: block;

      img {
        width: auto !important;
      }
    }
  }

  .owl-controls {
    .owl-nav {
      margin-top: 0;
    }
  }

  &.app-partner {
    padding: 50px 0 0;
    border: 0;
  }

  &.marketing-partner {
    border: {
      bottom: 1px dashed #edf5ff;
      top: 1px dashed #edf5ff;
    }
  }

  &.software-partner {
    border: {
      bottom: 1px dashed #edf5ff;
      top: none;
    }
  }

  &.startup-partner {
    border: {
      bottom: 1px dashed #edf5ff;
      top: none;
    }
  }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--templateColor);
    opacity: 0.67;
  }

  .section-title {
    h2,
    p {
      color: var(--whiteColor);
    }

    .bar {
      background: var(--whiteColor);

      &::before {
        background: var(--templateColor);
      }
    }
  }

  .newsletter-form {
    max-width: 650px;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;

    .form-control {
      height: 70px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 5px;
      color: var(--paragraphColor);
      padding-left: 20px;

      &::placeholder {
        color: var(--paragraphColor);
      }
    }

    .btn {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      &:hover,
      &:focus {
        transform: translateY(0);
      }
    }

    #validator-newsletter {
      position: absolute;
      bottom: -45px;
      color: var(--whiteColor);
      left: 0;
      font-size: 20px;

      &.validation-danger {
        color: red;
      }
    }
  }
}

.bubble-animate {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  .circle {
    background: var(--templateColor);
    bottom: 0;
    position: absolute;
    border-radius: 50%;

    &.small {
      width: 20px;
      height: 20px;
      opacity: 0.7;

      &.square1 {
        left: 18%;
        top: 100%;
        animation-name: smallBubble;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-delay: 1s;
        animation-timing-function: ease-in;
      }

      &.square2 {
        left: 36%;
        top: 100%;
        animation-name: smallBubble;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-delay: 2s;
        animation-timing-function: ease-in;
      }

      &.square3 {
        left: 54%;
        top: 100%;
        animation-name: smallBubble;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-delay: 1s;
        animation-timing-function: ease-in;
      }

      &.square4 {
        left: 72%;
        top: 100%;
        animation-name: smallBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 4s;
        animation-timing-function: ease-in;
      }

      &.square5 {
        left: 90%;
        top: 100%;
        animation-name: smallBubble;
        animation-duration: 7s;
        animation-delay: 5s;
        animation-timing-function: ease-in;
      }
    }

    &.medium {
      width: 40px;
      height: 40px;
      opacity: 0.35;

      &.square1 {
        left: 21%;
        top: 100%;
        animation-name: mediumBubble;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-delay: 2s;
        animation-timing-function: ease-in;
      }

      &.square2 {
        left: 42%;
        top: 100%;
        animation-name: mediumBubble;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-delay: 6s;
        animation-timing-function: ease-in;
      }

      &.square3 {
        left: 63%;
        top: 100%;
        animation-name: mediumBubble;
        animation-duration: 12s;
        animation-iteration-count: infinite;
        animation-delay: 12s;
        animation-timing-function: ease-in;
      }

      &.square4 {
        left: 84%;
        top: 100%;
        animation-name: mediumBubble;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-delay: 12s;
        animation-timing-function: ease-in;
      }

      &.square5 {
        left: 105%;
        top: 100%;
        animation-name: mediumBubble;
        animation-duration: 18s;
        animation-iteration-count: infinite;
        animation-delay: 6s;
        animation-timing-function: ease-in;
      }
    }

    &.large {
      width: 100px;
      height: 100px;
      opacity: 0.15;

      &.square1 {
        left: 21%;
        top: 100%;
        animation-name: bigBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 6s;
        animation-timing-function: ease-in;
      }

      &.square2 {
        left: 42%;
        top: 100%;
        animation-name: bigBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 3s;
        animation-timing-function: ease-in;
      }

      &.square3 {
        left: 63%;
        top: 100%;
        animation-name: bigBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 13s;
        animation-timing-function: ease-in;
      }

      &.square4 {
        left: 84%;
        top: 100%;
        animation-name: bigBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 9s;
        animation-timing-function: ease-in;
      }

      &.square5 {
        left: 105%;
        top: 100%;
        animation-name: bigBubble;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-delay: 13s;
        animation-timing-function: ease-in;
      }
    }
  }
}

@-webkit-keyframes smallBubble {
  0% {
    top: 100%;
    margin-left: 10px;
  }

  25% {
    margin-left: -10px;
  }

  50% {
    margin-left: 10px;
  }

  75% {
    margin-left: -10px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

@keyframes smallBubble {
  0% {
    top: 100%;
    margin-left: 10px;
  }

  25% {
    margin-left: -10px;
  }

  50% {
    margin-left: 10px;
  }

  75% {
    margin-left: -10px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

@-webkit-keyframes mediumBubble {
  0% {
    top: 100%;
    margin-left: 15px;
  }

  25% {
    margin-left: -15px;
  }

  50% {
    margin-left: 15px;
  }

  75% {
    margin-left: -15px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

@keyframes mediumBubble {
  0% {
    top: 100%;
    margin-left: 15px;
  }

  25% {
    margin-left: -15px;
  }

  50% {
    margin-left: 15px;
  }

  75% {
    margin-left: -15px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

@-webkit-keyframes bigBubble {
  0% {
    top: 100%;
    margin-left: 20px;
  }

  25% {
    margin-left: -20px;
  }

  50% {
    margin-left: 20px;
  }

  75% {
    margin-left: -20px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

@keyframes bigBubble {
  0% {
    top: 100%;
    margin-left: 20px;
  }

  25% {
    margin-left: -20px;
  }

  50% {
    margin-left: 20px;
  }

  75% {
    margin-left: -20px;
  }

  100% {
    top: 0%;
    opacity: 0;
    margin-left: 0px;
  }
}

/*================================================
Product CSS
=================================================*/
.single-product {
  box-shadow: 0 10px 50px 0 rgba(46, 65, 90, 0.1);
  margin-bottom: 30px;

  .product-img {
    position: relative;
    overflow: hidden;

    img {
      transform: scale(1);
      transition: all 300ms ease;
    }

    .add-to-cart-btn {
      display: block;
      position: absolute;
      left: 0;
      bottom: -45px;
      width: 100%;
      background: var(--blackColor);
      color: var(--whiteColor);
      height: auto;
      letter-spacing: 2px;
      padding: 12px 20px;
      text-transform: uppercase;

      font: {
        weight: 700;
        size: 14px;
      }

      overflow: hidden;
      transition: 0.4s;

      i {
        font-size: 20px;
        float: right;
      }
    }
  }

  .product-content {
    padding: 30px;

    h3 {
      font: {
        size: 20px;
        weight: 700;
      }

      margin-bottom: 15px;
    }

    .row {
      h5 {
        text-align: left;

        font: {
          size: 17px;
          weight: 700;
        }

        margin-bottom: 0;

        span {
          font-size: 14px;
          text-decoration: line-through;
          position: relative;
          top: -1px;
          color: var(--paragraphColor);
        }
      }

      ul {
        text-align: right;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          display: inline-block;
          color: #ffb708;
        }
      }
    }
  }

  &:hover {
    .product-img {
      img {
        transform: scale(1.09);
      }

      .add-to-cart-btn {
        bottom: 0;
      }
    }
  }
}

.product-slides {
  &.owl-theme {
    .owl-nav {
      margin: {
        top: 20px;
      }

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }
}

.woocommerce-topbar {
  margin-bottom: 30px;
}

.woocommerce-topbar-ordering {
  .form-select {
    padding: 12px 15px;
  }
}

/*================================================
Product Details CSS
=================================================*/
.shop-details-area {
  padding-bottom: 70px;
}

.shop-details {
  background: var(--whiteColor);
  padding: 30px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

  .product-entry-summary {
    h3 {
      font: {
        size: 23px;
        weight: 700;
      }

      text-transform: capitalize;
      margin-bottom: 0;
    }

    h4 {
      font: {
        size: 18px;
        weight: 600;
      }

      margin: 17px 0;

      span {
        color: var(--paragraphColor);
        text-decoration: line-through;
      }
    }

    p {
      margin-bottom: 17px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      &.product-categories {
        li {
          display: inline-block;
          font-weight: 600;

          a {
            color: var(--paragraphColor);

            &:hover {
              color: var(--templateColor);
            }
          }
        }
      }

      &.share-social {
        li {
          display: inline-block;
          font-weight: 700;

          a {
            color: var(--paragraphColor);
            width: 35px;
            height: 35px;
            border: 1px solid var(--paragraphColor);
            text-align: center;
            border-radius: 50%;
            line-height: 35px;
            margin-left: 6px;

            &:hover {
              color: var(--whiteColor);
              background: var(--templateColor);
              border-color: var(--templateColor);
            }
          }
        }
      }
    }

    form {
      margin: {
        top: 30px;
        bottom: 30px;
      }

      .form-control {
        display: inline-block;
        width: 120px;
      }

      .btn {
        display: inline-block;
        position: relative;
        top: -1px;
        margin-left: 10px;
      }
    }
  }

  .owl-theme {
    .owl-nav {
      margin-top: 20px;

      [class*="owl-"] {
        color: var(--templateColor2);
        font-size: 30px;
        margin: 0 3px;
        padding: 0;
        background: transparent !important;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: 0.4s;
        line-height: 1;

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .owl-controls {
      line-height: 0.1;
    }
  }

  .shop-details-tabs {
    margin-top: 40px;

    #tabs {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        display: inline-block;
        padding: 12px 50px;
        cursor: pointer;

        font: {
          weight: 700;
          size: 16px;
        }

        background: #eaeaea;

        &.active {
          background-color: var(--templateColor);
          position: relative;
          color: #fff;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            top: 0;
            left: 0;
            background: var(--templateColor);
          }
        }

        &.inactive {
          background-color: #eeeeee;
          padding: 12px 50px;
        }
      }
    }

    .content {
      .shop-description {
        border: 1px solid #eee;
        padding: 30px;
      }

      display: none;

      &.show {
        display: block;
      }

      h3 {
        margin-bottom: 20px;

        font: {
          size: 23px;
          weight: 700;
        }
      }

      .shop-reviews {
        padding: 30px;
        border: 1px solid #eee;

        b {
          margin: 0 0 15px;
          display: inline-block;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            display: inline-block;
            position: relative;

            margin: {
              right: 15px;
            }

            &::before {
              content: "";
              position: absolute;
              width: 1px;
              height: 13px;
              background: #eeeeee;
              left: -10px;
              top: 6px;
            }

            &:first-child {
              &::before {
                display: none;
              }
            }

            a {
              color: #ff9800;
            }
          }
        }

        .review-form {
          margin-top: 30px;
        }
      }
    }
  }
}

.shop-related-prodcut {
  margin-top: 100px;

  .section-title {
    text-align: left;

    margin: {
      left: 0;
      right: 0;
    }

    .bar {
      margin: {
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-banner {
  position: relative;
  background: var(--templateColor);
  z-index: 1;

  padding: {
    top: 200px;
    bottom: 120px;
  }

  text-align: center;

  .container {
    position: relative;
  }

  h2 {
    margin: 0;

    font: {
      size: 35px;
      weight: 700;
    }

    color: var(--whiteColor);
  }

  .bubble-animate {
    .circle {
      background: var(--whiteColor);
    }
  }
}

/*=============================
Pagination CSS
===============================*/
.pagination-area {
  text-align: center;
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0;

    .page-item {
      .page-link-dot {
        padding: 11px 20px;
        margin: 0 3px;
        color: #6f8ba4;
        background-color: var(--whiteColor);
        border: none;
        border-radius: 5px;
        font-size: 16px;
        box-shadow: 0 2px 10px 0 #d8dde6;
      }

      .page-link {
        padding: 11px 20px;
        margin: 0 3px;
        color: #6f8ba4;
        background-color: var(--whiteColor);
        border: none;
        border-radius: 5px;
        font-size: 16px;
        box-shadow: 0 2px 10px 0 #d8dde6;

        &:hover,
        &:focus {
          color: var(--whiteColor);
          background-color: var(--templateColor);
          box-shadow: unset;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #cccccc;
          color: #666666;
          color: var(--whiteColor);
        }

        &.active {
          color: var(--whiteColor);
          background-color: var(--templateColor);
        }
      }

      &.active {
        .page-link {
          color: var(--whiteColor);
          background-color: var(--templateColor);
        }
      }
    }
  }
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar-area {
  .widget {
    margin-bottom: 40px;

    &.widget-search {
      form {
        position: relative;

        .form-control {
          background: #edf5ff;
          border-color: transparent;

          &:focus {
            border-color: var(--templateColor);
          }
        }

        button {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 55px;
          border: none;
          background: transparent;
          cursor: pointer;
          transition: 0.4s;

          &:hover {
            background: var(--templateColor);
            color: var(--whiteColor);
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title {
      font: {
        weight: 700;
        size: 23px;
      }

      margin-bottom: 0;
    }

    .bar {
      height: 4px;
      width: 65px;
      background: var(--templateColor);
      margin: 12px 0 30px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background: var(--whiteColor);
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: MOVE-BG;
      }
    }

    &.widget_product_categories {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: var(--paragraphColor);

            font: {
              weight: 600;
            }

            position: relative;

            &:hover {
              color: var(--templateColor);
              padding-left: 17px;

              i {
                opacity: 1;
                visibility: visible;
                left: 0;
              }
            }

            i {
              position: absolute;
              left: 8px;
              top: 50%;
              margin-top: -8px;
              opacity: 0;
              visibility: hidden;
              transition: 0.4s;
            }
          }
        }
      }
    }

    &.widget_post_categories {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: var(--paragraphColor);

            font: {
              weight: 600;
            }

            position: relative;

            &:hover {
              color: var(--templateColor);
              padding-left: 17px;

              i {
                opacity: 1;
                visibility: visible;
                left: 0;
              }
            }

            i {
              position: absolute;
              left: 8px;
              top: 50%;
              margin-top: -8px;
              opacity: 0;
              visibility: hidden;
              transition: 0.4s;
            }
          }
        }
      }
    }

    &.widget_tag_cloud {
      .tagcloud {
        a {
          background: #f5f9ff;
          color: #938da6;
          padding: 6px 26px;
          border-radius: 30px;
          margin-bottom: 7px;
          font-weight: 600;
          margin-right: 5px;
          font-size: 15px;

          &:hover {
            color: var(--whiteColor);
            background: var(--templateColor);
          }
        }
      }
    }

    &.widget_recent_posts {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          overflow: hidden;
          margin-bottom: 15px;
          padding-left: 110px;

          &:last-child {
            margin-bottom: 0;
          }

          .recent-post-thumb {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;

            a {
              display: block;
            }
          }

          .recent-post-content {
            h3 {
              font: {
                size: 16px;
                weight: 700;
              }

              line-height: 23px;
            }

            .date {
              display: block;
              margin-top: 3px;
              font-size: 14px;
              color: #828893;
            }
          }
        }
      }
    }

    &.widget_recent_products {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          overflow: hidden;
          margin-bottom: 15px;
          padding-left: 110px;

          &:last-child {
            margin-bottom: 0;
          }

          .recent-products-thumb {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;

            a {
              display: block;
            }
          }

          .recent-products-content {
            h3 {
              font: {
                size: 16px;
                weight: 700;
              }

              line-height: 23px;
            }

            .date {
              display: block;
              margin-top: 3px;
              font-size: 14px;
              color: #828893;
            }

            ul {
              margin: -3px 0 -3px;

              li {
                display: inline-block;
                padding: 0;
                margin: 0;
                line-height: 1;
                color: #ffba00;
                margin-right: 1px;
              }
            }
          }
        }
      }
    }

    &.widget_text {
      ul {
        padding: 0;
        margin: 0 -3px;
        list-style-type: none;
        overflow: hidden;

        li {
          float: left;
          padding: 0 3px;
          width: 33.3333%;
          margin-bottom: 6px;
        }
      }
    }
  }
}

/*================================================
Login CSS
=================================================*/
.login-form {
  background: var(--whiteColor);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 8px;
  }

  p {
    margin-bottom: 30px;
  }

  form {
    .form-check {
      margin-top: 18px;

      .form-check-label {
        color: var(--paragraphColor);
      }
    }

    .forgot-password {
      margin: {
        bottom: 20px;
        top: 18px;
      }

      a {
        color: var(--paragraphColor);

        &:hover {
          color: var(--templateColor);
        }
      }
    }

    .btn {
      margin: {
        bottom: 20px;
        top: 10px;
      }
    }

    span {
      display: block;
      color: var(--paragraphColor);

      a {
        color: var(--templateColor2);
      }
    }
  }
}

/*================================================
Signup CSS
=================================================*/
.signup-form {
  background: var(--whiteColor);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 25px;
  }

  form {
    .form-check {
      margin-top: 18px;

      .form-check-label {
        color: var(--paragraphColor);
        position: relative;
        top: -2px;
      }
    }

    .btn {
      margin: {
        bottom: 20px;
        top: 20px;
      }
    }

    span {
      display: block;
      color: var(--paragraphColor);

      a {
        color: var(--templateColor2);
      }
    }
  }
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  background: var(--whiteColor);
  border-radius: 10px;

  .accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: none;

    .accordion__item {
      display: block;
      margin-bottom: 15px;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }

      .accordion-title {
        display: block;
        padding: 20px 30px;
        text-decoration: none;
        background: #f5f9ff;

        font: {
          size: 20px;
          weight: 700;
        }

        &:hover,
        &.active,
        &:focus {
          background: var(--templateColor);
          color: var(--whiteColor);
        }

        &.active {
          border-radius: 0;
        }
      }

      .accordion__panel {
        position: relative;
        padding: 15px;

        P {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .accordion__button {
    transition: 0.5s;
    background-color: #f5f9ff;
    font-size: 18px;
    font-weight: 600;
    outline: 0;

    &:hover,
    &:focus {
      color: var(--whiteColor);
      background-color: var(--templateColor);
    }
  }
}

.faq-contact {
  margin-top: 45px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  border-radius: 4px;

  h3 {
    font: {
      size: 35px;
      weight: 700;
    }

    margin-bottom: 25px;
  }

  form {
    .btn {
      margin-top: 15px;
    }
  }
}

/*================================================
Error 404 CSS
=================================================*/
.notfound {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 40px;
  padding-left: 280px;
  max-width: 820px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  border-radius: 50px 5px 5px 50px;

  .notfound-404 {
    position: absolute;
    left: 0;
    top: 50%;
    display: inline-block;
    width: 140px;
    height: 140px;

    background: {
      size: cover;
    }

    margin-top: -68px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(2.4);
      border-radius: 50%;
      background-color: #f2f5f8;
      z-index: -1;
    }
  }

  h2 {
    font: {
      size: 60px;
      weight: 700;
    }

    margin-bottom: 10px;
  }

  span {
    display: block;
    margin-bottom: 20px;
    color: var(--paragraphColor);
    font-size: 18px;
  }
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  text-align: center;

  &::before {
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--blackColor);
    opacity: 0.77;
    content: "";
    position: absolute;
  }

  .coming-soon-content {
    max-width: 650px;
    margin: 0 auto;

    h1 {
      color: var(--whiteColor);

      font: {
        size: 45px;
        weight: 900;
      }

      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 55px;
    }

    p {
      color: #c7c3c7;

      margin: {
        top: 20px;
        bottom: 35px;
      }
    }

    form {
      position: relative;

      .form-control {
        background: transparent;
        border: 1px solid var(--whiteColor);
        border-radius: 50px;
        color: var(--whiteColor);
        height: 55px;

        &:focus {
          border-color: var(--templateColor);
        }
      }

      button {
        position: absolute;
        right: 0;
        height: 100%;
        background: var(--templateColor);
        top: 0;
        color: var(--whiteColor);
        border: none;
        width: 130px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 0 50px 50px 0;
        transition: 0.4s;

        &:hover {
          background: var(--templateColor2);
        }
      }
    }

    #timer {
      margin-bottom: 35px;

      div {
        display: inline-block;

        font: {
          size: 45px;
          weight: 900;
        }

        color: var(--whiteColor);
        width: 135px;
        height: 135px;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        padding-top: 34px;
        line-height: 40px;
        margin: 0 5px;
      }

      span {
        display: block;

        font: {
          size: 17px;
          weight: 600;
        }
      }
    }
  }
}

/*================================================
Cart CSS
=================================================*/
.cart-table {
  table {
    margin-bottom: 0;
    text-align: center;

    thead {
      tr {
        th {
          border-bottom-width: 0px;
          vertical-align: middle;
          font-weight: 700;
          border-color: #eaedff;
          padding: 15px 20px;
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          color: var(--paragraphColor);
          border-color: #eaedff;

          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 130px;
              }
            }
          }

          &.product-name {
            a {
              color: var(--paragraphColor);
              font-weight: 600;

              &:hover {
                color: var(--templateColor);
              }
            }
          }

          &.product-remove {
            a {
              color: red;
            }
          }
        }
      }
    }
  }
}

.cart-buttons {
  margin-top: 45px;

  .coupon-box {
    position: relative;
    max-width: 570px;

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 200px;
      border: none;
      background: var(--templateColor2);
      transition: 0.4s;
      cursor: pointer;
      border-radius: 0 5px 5px 0;
      color: var(--whiteColor);
      font-weight: 600;

      &:hover {
        background: var(--templateColor);
      }
    }
  }
}

.cart-totals {
  margin-top: 45px;
  background: var(--whiteColor);
  padding: 40px;
  max-width: 600px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-left: auto;

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 25px;
  }

  ul {
    padding: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      border: 1px solid #eaedff;
      padding: 10px 15px;
      color: var(--paragraphColor);
      overflow: hidden;

      &:first-child {
        border-bottom: none;
      }

      span {
        float: right;
        color: var(--blackColor);
      }
    }
  }
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 18px 25px 15px;
  margin-bottom: 45px;
  border-top: 3px solid var(--templateColor);

  i {
    color: var(--templateColor);
    margin-right: 4px;
  }

  span {
    display: inline-block;
    color: var(--templateColor);

    a {
      font-weight: 600;
    }
  }
}

.checkout-area {
  .title {
    font: {
      size: 23px;
      weight: 700;
    }

    margin-bottom: 0;
  }

  .bar {
    height: 4px;
    width: 65px;
    background: var(--templateColor);
    margin: 12px 0 30px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 5px;
      background: var(--whiteColor);
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: MOVE-BG;
    }
  }
}

.billing-details {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 25px;
  border-radius: 5px;

  .form-group {
    label {
      display: block;
      color: var(--paragraphColor);
      margin-bottom: 7px;
      font-size: 16px;

      .required {
        color: red;
      }
    }
  }

  .form-check {
    margin-bottom: 15px;

    .form-check-label {
      color: var(--paragraphColor);
    }
  }
}

.order-details {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 25px;
  border-radius: 5px;

  .order-table {
    table {
      margin-bottom: 0;
      text-align: left;

      thead {
        tr {
          th {
            border-bottom-width: 0px;
            vertical-align: middle;

            font: {
              weight: 700;
              size: 16px;
            }

            border-color: #eaedff;
            padding: 15px 20px;
          }
        }
      }

      tbody {
        tr {
          td {
            vertical-align: middle;
            color: var(--paragraphColor);
            border-color: #eaedff;
            font-size: 15px;

            &.product-name {
              a {
                color: var(--paragraphColor);
                font-weight: 600;

                &:hover {
                  color: var(--templateColor);
                }
              }
            }

            &.order-subtotal,
            &.order-shipping,
            &.total-price {
              span {
                color: var(--blackColor);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }

  .payment-method {
    margin-top: 40px;
    border-top: 1px solid #eaedff;
    padding-top: 35px;

    p {
      font: {
        size: 14px;
      }

      [type="radio"] {
        &:checked,
        &:not(:checked) {
          position: absolute;
          left: -9999px;
        }
      }

      [type="radio"] {
        &:checked,
        &:not(:checked) {
          + label {
            padding-left: 30px;
            cursor: pointer;
            display: block;

            font: {
              size: 16px;
              weight: 700;
            }

            color: var(--blackColor);
            position: relative;
            margin-bottom: 8px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 3px;
              width: 18px;
              height: 18px;
              border: 1px solid #dddddd;
              border-radius: 50%;
              background: var(--whiteColor);
            }

            &::after {
              content: "";
              width: 12px;
              height: 12px;
              background: var(--templateColor);
              position: absolute;
              top: 6px;
              left: 3px;
              border-radius: 50%;
              transition: all 0.2s ease;
            }
          }
        }
      }

      [type="radio"] {
        &:not(:checked) {
          + label {
            &::after {
              opacity: 0;
              visibility: hidden;
              transform: scale(0);
            }
          }
        }
      }

      [type="radio"] {
        &:checked {
          + label {
            &::after {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .btn {
    &.order-btn {
      display: block;
      margin-top: 25px;
      width: 100%;

      &.btn-disabled {
        background-color: #717070;
        border-color: #717070;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
          border-color: var(--blackColor);
        }
      }
    }
  }
}

/*================================================
Contact Us CSS
=================================================*/
.contact-info-area {
  border-bottom: 1px solid #eee;
}

.contact-info-box {
  text-align: center;
  transition: 0.4s;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 30px;

  .icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #edf5ff;
    border-radius: 50%;
    font-size: 30px;
    color: var(--templateColor);
    transition: 0.4s;
  }

  h3 {
    font: {
      size: 23px;
      weight: 700;
    }

    margin: 25px 0 15px;
  }

  p {
    margin-bottom: 0;

    a {
      color: var(--paragraphColor);

      &:hover {
        color: var(--templateColor);
      }
    }
  }

  &:hover {
    transform: translateY(-10px);

    .icon {
      color: var(--whiteColor);
      background: var(--templateColor2);
    }
  }
}

#map {
  height: 500px;
  width: 100%;
}

#contactForm {
  .help-block {
    ul {
      margin: 0;

      li {
        color: red;
      }
    }
  }

  .btn {
    margin-top: 10px;
  }

  #msgSubmit {
    &.text-danger,
    &.text-success {
      margin: 20px 0 0;
    }
  }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  position: relative;
  z-index: 1;

  .gcn-bottom {
    padding: 6px 14px;
    color: #333333;
    padding: 1.5rem 5rem;
    font-weight: 600;
  }

  .logo__content {
    margin-bottom: 36px;
  }

  .single-footer-widget {
    .logo {
      display: block;
      margin-bottom: 20px;

      img {
        max-width: 110px;
        height: auto;
      }
    }

    .about__content {
      .about__title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
        color: var(--whiteColor);
      }

      .about__main {
        margin-top: 16px;

        .about__main-item {
          display: flex;
          flex: 1;
          align-items: flex-start;
          margin-bottom: 12px;

          .about__main-item-icon {
            margin-right: 10px;
          }
        }

        .about__main-item-content {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: var(--primaryColor3);
        }
      }
    }

    p {
      color: var(--whiteColor);
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      &.social-list {
        margin-top: 20px;

        li {
          display: inline-block;
          margin-right: 10px;

          a {
            color: var(--whiteColor);

            &:hover {
              color: var(--templateColor3);
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.list {
        li {
          margin-bottom: 9px;

          .work__time {
            display: flex;
            flex: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: var(--primaryColor3);

            .work__time-day {
              width: 90px;
            }
          }

          a {
            display: block;
            color: var(--primaryColor3);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;

            &:hover {
              color: var(--whiteColor);
              padding-left: 7px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .menu__title {
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 16px;
    }
  }

  .bg-image {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 130px !important;
    padding-bottom: 20px !important;

    background: {
      position: inherit;
      size: cover;
      repeat: no-repeat;
    }
  }
}

.copyright-area {
  padding: 30px 0;
  background-color: var(--primaryColor2);

  .container {
    p {
      color: var(--whiteColor);
    }

    .copy__right-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: var(--whiteColor);
    }

    .bottom__social-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bottom__social-footer-text {
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: var(--whiteColor);
      }
    }
  }

  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      padding-left: 17px;

      &::before {
        content: "";
        position: absolute;
        left: 7px;
        top: 5px;
        background: var(--templateColor2);
        width: 2px;
        height: 12px;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      a {
        color: var(--whiteColor) !important;

        &:hover {
          color: var(--templateColor);
        }
      }
    }
  }
}

/*================================================
Back To Top CSS
=================================================*/
.back-to-top {
  .top {
    position: fixed;
    right: 1.5%;
    display: block;
    bottom: 1.25%;
    visibility: visible;
    height: 15px;
    cursor: pointer;
    width: 40px;
    padding: 3px 5px;

    font: {
      size: 10px;
      weight: bold;
    }

    color: transparent;
    text-align: center;
    z-index: 3;
    outline: 0 !important;
    transition: all 0.25s ease-in-out;
    background-color: var(--primaryColor2);

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top: 0;
    }

    &::before {
      top: -20px;
      z-index: 4;
      border-bottom: 20px solid var(--primaryColor2);
    }

    &::after {
      bottom: 0;
      z-index: 5;
      border-bottom: 20px solid var(--templateColor);
    }

    &:hover,
    &:focus {
      opacity: 1;
      text-decoration: none;
      height: 40px;
      color: var(--whiteColor);
    }
  }
}

.fade:not(.show) {
  opacity: 1;
}

.input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;

  span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #d0d0d0;
    width: 40px;
    height: 100%;
    line-height: 50px;
    transition: var(--transition);

    &.minus-btn {
      left: 0;
    }

    &.plus-btn {
      right: 0;
    }

    &:hover {
      color: var(--blackColor);
    }
  }

  input {
    height: 45px;
    color: var(--blackColor);
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;

    font: {
      size: 17px;
      weight: 600;
    }

    &::placeholder {
      color: var(--blackColor);
    }
  }
}

.thank-you-area {
  text-align: center;

  padding: {
    top: 150px;
    bottom: 150px;
  }

  h1 {
    margin-bottom: 15px;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.modal-video-close-btn {
  cursor: pointer;
}

.join-img {
  text-align: center;
}

.text-right {
  text-align: right;
}

textarea.form-control {
  height: auto !important;
}

.content-body {
  position: relative;
  height: calc(100vh - 48px);
  overflow: hidden;

  .benefit-bg {
    position: absolute;
    z-index: 0;
    left: -15%;
    top: 5%;
    height: 70vh;
  }

  &.content-body__toolbar {
    height: calc(100vh - 92px);
  }
}

/*===================
Modal Download
===================*/
.modal-tool-area {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  z-index: 9999;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-tool-box {
    width: 1000px;
    height: 638px;
    background: white;
    border: 2px solid;
    box-sizing: border-box;
    box-shadow: 0px 32px 80px rgba(17, 17, 17, 0.08);
    border-radius: 30px;
    padding: 30px;
    max-height: 98%;

    .modal-tool-title {
      margin-bottom: 32px;

      span {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: 0.01em;
      }
    }

    .modal-tool-box-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;

      .close-icon {
        &:hover,
        &:focus {
          .close-frame {
            stroke: #d81616;
          }
        }
      }
    }

    .modal-tool-box-content {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      .left {
        margin: auto;
      }

      .modal-tool-content {
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 140%;
          color: #7c7c80;
        }
      }

      .modal-tool-img {
        width: 684px;
        height: 506px;
        position: relative;

        img {
          max-height: 506px;
          width: auto;
        }

        .modal-tool {
          position: absolute;

          &.modal-imac {
            top: 0;
            left: 0;
            z-index: 9999;
            transition: 0.4s;
            animation: slide-in 0.5s forwards;
            -webkit-animation: slide-in 0.5s forwards;
          }

          &.modal-phone {
            bottom: 0;
            right: 0;
            z-index: 10000;
            transition: 0.4s;
            animation: slide-in 0.5s forwards;
            -webkit-animation: slide-in 0.5s forwards;
          }
        }
      }
    }
  }
}

.modal-download-desktop {
  max-width: 600px !important;
  height: 450px !important;

  .frame__download {
    padding: 12px;
    background-color: var(--whiteColor);
    border-radius: 12px;
    border: 2.31411px solid #eff0f7;
    cursor: pointer;

    &:hover {
      box-shadow: 0 13px 27px 0 #d8d8d8;
    }

    margin-bottom: 20px;
  }

  .modal-download-desktop-title {
    text-align: center;
    align-self: center;
    margin-bottom: 0px;
    margin-top: 20px;

    span {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 140%;
      margin-bottom: 0px;
      text-align: center;
      text-transform: uppercase;
      color: var(--primaryColor);
    }

    p {
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 140%;
      margin-bottom: 0px;
      text-align: center;
      text-transform: uppercase;
      color: var(--primaryColor);
    }
  }

  .modal-download-desktop-footer {
    padding: 40px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

button {
  outline: 0 !important;
}
