/*
This will help you to create spacing classes for margin and padding.
Eg: mt-0 , pl-5
  
The first letter represents the spacing type ( Margin/Padding ).
m:  margin 
p:  padding 
The second letter represents the direction.
l: left
r: right
t: top
b: bottom
v: vertical (top and bottom)
h: horizontal (left and right)
a: all
The third represents the spacing value (which you can give your own)
For eg: 'mb-0' means margin-bottom: 0;
*/

$spacing: 2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70; // Modify this to generate your spacing classes
$unit: px; // Modify this to specify your unit eg: em, pt, %.

$directions: (
    l: left,
    t: top,
    r: right,
    b: bottom,
    v: top,
    h: left
);

@mixin generateSpacing($prop, $short ) {
    @each $space in $spacing {
        .#{$short}a#{$space} {
            #{$prop}: $space + $unit
        }

        @each $key,
        $val in $directions {
            .#{$short}#{$key}#{$space} {
                #{$prop}-#{$val}: $space + $unit;

                @if $key ==v {
                    #{$prop}-bottom: $space + $unit;
                }

                @if $key ==h {
                    #{$prop}-right: $space + $unit;
                }
            }
        }
    }
}

@include generateSpacing(padding, p);
@include generateSpacing(margin, m);