.fallback-spinner {
    min-height: calc(100vh - 460px);
    display: flex;
    justify-content: center;
    align-items: center;

    .bg-spin {
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1;
        position: absolute;
        z-index: 99;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(#f1f1f1, 0.5);
    }


    .loading {
        position: absolute;
        left: calc(50% - 35px);
        top: 50%;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 3px solid transparent;
        z-index: 100;

        &.component-loader {
            margin-top: -60px;
        }

        .effect-1,
        .effect-2 {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 3px solid transparent;
            border-left: 3px solid var(--primaryColor);
            border-radius: 50%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .effect-1 {
            animation: rotate 1s ease infinite;
        }

        .effect-2 {
            animation: rotateOpacity 1s ease infinite 0.1s;
        }

        .effect-3 {
            width: 100%;
            height: 100%;
            border: 3px solid transparent;
            border-left: 3px solid var(--primaryColor1);
            -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
            animation: rotateOpacity 1s ease infinite 0.2s;
            border-radius: 50%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .loading .effects {
            transition: all 0.3s ease;
        }
    }

    .fallback-logo {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 30%;
    }

    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
        }
    }

    @keyframes rotateOpacity {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            opacity: 0.1;
        }

        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
            opacity: 1;
        }
    }
}

.fallback-spinner-v2 {
    min-height: calc(100vh - 460px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        .mindmap-loading {
            zoom: 0.4;
        }
    }

    .mindmap-loading {
        position: relative;
        width: 512px;
        height: 512px;

        .main {
            position: absolute;
            width: 67.85px;
            height: 68.08px;
            left: 233.71px;
            top: 222.94px;
            animation: rotate 1s ease infinite;
        }

        .line1 {
            position: absolute;
            left: 9.15%;
            right: 46.12%;
            top: 16.02%;
            bottom: 51.75%;
        }

        .line2 {

            position: absolute;
            left: 53.62%;
            right: 6.18%;
            top: 19.84%;
            bottom: 49.36%;
        }

        .line3 {
            position: absolute;
            left: 53.97%;
            right: 0%;
            top: 49.51%;
            bottom: 23.86%;

        }

        .line4 {
            position: absolute;
            left: 8.42%;
            right: 46.95%;
            top: 52.4%;
            bottom: 15.99%;
        }

        .line5 {
            position: absolute;
            left: 0%;
            right: 52.93%;
            top: 32.63%;
            bottom: 46.66%;
        }
    }

    @keyframes increase {
        100% {
            width: 300px;
        }
    }

    @keyframes widthAnimation {
        0% {
            opacity: 0;
            width: 0.1%;
            // transform: translateX(-10px) translateY(-10px);
        }

        25% {
            opacity: 0.25;
            width: 25%;

            // transform: translateX(0px) translateY(0px);
        }

        50% {
            opacity: 0.5;
            width: 50%;

            // transform: translateX(0px) translateY(0px);
        }

        75% {
            opacity: 0.75;
            width: 75%;

            // transform: translateX(0px) translateY(0px);
        }

        100% {
            opacity: 1;
            width: 100%;

            // transform: translateX(-20px) translateY(20px);
        }
    }

    @keyframes fadeInLine2 {
        0% {
            opacity: 0;
            transform: translateX(0) translateY(0);
        }

        100% {
            opacity: 1;
            transform: translateX(20px) translateY(20px);
        }
    }

    @keyframes fadeInLine3 {
        0% {
            opacity: 0;
            transform: translateX(0) translateY(0);
        }

        100% {
            opacity: 1;
            transform: translateX(20px) translateY(-20px);
        }
    }

    @keyframes fadeInLine4 {
        0% {
            opacity: 0;
            transform: translateX(0) translateY(0);
        }

        100% {
            opacity: 1;
            transform: translateX(-20px) translateY(-20px);
        }
    }

    @keyframes fadeInLine5 {
        0% {
            opacity: 0;
            transform: translateX(0) translateY(0);
        }

        100% {
            opacity: 1;
            transform: translateX(-20px) translateY(0px);
        }
    }

    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
        }
    }

    @keyframes rotateOpacity {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            opacity: 0.1;
        }

        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
            opacity: 1;
        }
    }
}