:root {
  --primaryColor: #8137fa;
  --primary50: #f7f2ff;
  --primaryColor1: #f7f2ff;
  --primaryColor5: #ebe3fe;
  --primaryColor2: #8137fa;
  --primaryColor3: #8137fa;
  --primaryColor4: #8137fa;
  --primaryColor6: #cab3fd;
  --bannerColor: #335dff;
  --fontFamily: "Brandon Text";
  --templateColor: #8137fa;
  --templateColor2: #8137fa;
  --whiteColor: #ffffff;
  --lightColor: #f8f8fc;
  --lightColor1: #f7f7f7;
  --darkColor: #7c7c80;
  --blackColor: #000000;
  --paragraphColor: #818992;
  --transition: 0.5s;
  --darkColor1: #474747;
  --blue: #4a50ff;
  --blueActive: #7d81f4;
  --pink: #d74a96;
  --pinkHover: #f076b8;
  --blueSky: #1b95ed;
  --lightBlue: #1999d6;
  --lightBlueHover: #1763fb;
  --mint: #24b6b1;
  --mintHover: #2c9491;
  --green: #47821c;
  --greenHover: #5bb01c;
  --purpule: #7b70ff;
  --purpleHover: #5145e2;
  --yellow: #ffc736;
  --yellowHover: #fbb911;
  --breadcrumbtextcolor: #d1c4e9;
  --breadcrumbtextcoloractive: #fff;
  --textBlack: #2e3233;
  --textBlack1: #333333;
  --grey50: #737c80;
  --grey40: #5c6366;
  --grey10: #f2f2f2;
  --grey20: #cccccc;
  --grey60: #f9f9f9;
  --grey70: #dddde3;
  --grey80: #a1aeb3;
  --grey30: #454b4d;
  --lightBlue1: #d9efff;
  --lightBlue2: #cfe0e6;
  --lightOrange: #ffa526;
  --darkColor2: #454b4d;
  --textColor: "#0B1B35";
  --primaryGardient: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.15)
    ),
    linear-gradient(0deg, rgba(63, 162, 247, 0.1), rgba(63, 162, 247, 0.1)),
    #8137fa;
  --primaryGardient1: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    ),
    #8137fa;
  --dark2: #2a2a2a;
  --grey90: #6a6969;
  --lightText30: #b3b3b3;
  --lightText60: #666666;
  --lightRed50: #ff0040;
  --lightPrimary10: #ded0fe;
  --lightPrimary20: #b697fc;
  --lightPrimary30: #fcfaff;
  --lightPrimary40: #a27afc;
  --lightGreen50: #83ca00;
  --lightGreen70: #f9fcf2;
  --lightText40: #999999;
  --lightText70: #4d4d4d;
  --lightText5: #f2f2f2;
  --lightText50: #808080;
  --lightPrimary5: #fefdff;
  --lightText90: #1a1a1a;
  --lightText80: #333333;
  --lightText10: #e5e5e5;
  --blue6: #1890ff;
  --lightSecondary5: #fbf9ff;
  --blue5: #40a9ff;
}
